//Character
export const ADD_DISCIPLINE = "ADD_DISCIPLINE"
export const SET_ATTRIBUTE = "SET_ATTRIBUTE"
export const SET_PROPERTY = "SET_PROPERTY"
export const SET_SKILL = "SET_SKILL"
export const LOCK_CHANGE = "LOCK_CHANGE"
export const GET_RANDOM = "GET_RANDOM"
export const SET_CONSUMABLE = "SET_CONSUMABLE"
export const SET_CHECKBOX = "SET_CHECKBOX"
export const SET_FIELD = "SET_FIELD"
export const GET_CHARACTER = "GET_CHARACTER"
export const SET_DISCIPLINE_VALUE = "SET_DISCIPLINE_VALUE"
export const SET_DISCIPLINE_NAME = "SET_DISCIPLINE_NAME"
export const SET_PICTURE = "SET_PICTURE"
export const GET_CHRONICLES = "GET_CHRONICLES"

//User
export const ADD_CHARACTER = "ADD_CHARACTER"
export const DEL_CHARACTER = "DEL_CHARACTER"