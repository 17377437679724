declare var require: any

var React = require('react');

export default class CharacterField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            name: null,
            label: null,
            value: '',
            onChangeField: null,
            multiline: false,
            rows: 6,
            cols: 48
        };
    }

    handleChange(e) {
        this.props.onChange(this.props.name, e.target.value);
    }

    render() {
        const multiline = this.props.multiline ? true : false;
        if (multiline) {
            return (
                <div className='table character-field-multi'>
                    <div className='row'>
                        <div className='col-12 label'>
                            <label>{this.props.label}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <textarea type='text' id={'txt' + this.props.name} name={'txt' + this.props.name} value={this.props.value ? this.props.value : ''}
                                onChange={e => this.props.onChangeField(this.props.name, e.target.value)}
                                rows={this.props.rows ? this.props.rows : this.state.rows} cols={this.props.cols ? this.props.cols : this.state.cols} />
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className='charAttribute character-field'>
                    <label>{this.props.label}</label>
                    <input type='text' id={'txt' + this.props.name} name={'txt' + this.props.name} value={this.props.value ? this.props.value : ''}
                        onChange={e => this.props.onChangeField(this.props.name, e.target.value)} />
                </div>
            );
        }
    }
}