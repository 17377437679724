declare var require: any

var React = require('react');

import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes'
import * as ROLES from '../constants/roles'
import { userStore } from '../store/storeModule'
import { withAuthorization } from '../session/session';
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import { compose } from 'recompose';
import { DefaultNodeModel, DiagramModel } from '@projectstorm/react-diagrams';

class MapsBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            relMaps: [],
            loadingMaps: false,
            players: [],
            addingMap: false,
            renamingMap: false,
            renamingId: null,
            newName: ''
        };

        this.selMap = this.selMap.bind(this);
        this.delMap = this.delMap.bind(this);
        this.showRenameMap = this.showRenameMap.bind(this);
    }

    unsubscribe: any;

    addMap() {
        const authUser = this.props.authUser;
        if (this.state.addingMap) {
            this.props.firebase.user(authUser.uid).get().then(currentUser => {
                if (currentUser.data().characters.length > 0) {
                    this.props.firebase.character(currentUser.data().characters[0]).get().then(doc => {
                        const model = new DiagramModel();
                        const newNode = new DefaultNodeModel({
                            name: doc.data().name,
                            color: 'rgb(0,192,255)',
                        });

                        newNode.setPosition(100, 100);
                        newNode.addOutPort('');
                        model.addAll(newNode);

                        var str = JSON.stringify(model.serialize());
                        this.props.firebase.relMaps().add({
                            name: this.state.newName,
                            owner: authUser.uid,
                            model: str
                        }).then((newMap) => {
                            var relMaps = [];
                            if (currentUser.data().relMaps) {
                                relMaps = [...currentUser.data().relMaps];
                            }

                            relMaps.push(newMap.id)

                            this.props.firebase.user(authUser.uid).set({
                                relMaps: relMaps
                            }, { merge: true });

                            this.setState({ addingMap: false, renamingMap: false });
                        });
                    });
                } else {
                    const model = new DiagramModel();

                    var str = JSON.stringify(model.serialize());
                    this.props.firebase.relMaps().add({
                        name: this.state.newName,
                        owner: authUser.uid,
                        model: str
                    }).then((newMap) => {
                        var relMaps = [];
                        if (currentUser.data().relMaps) {
                            relMaps = [...currentUser.data().relMaps];
                        }

                        relMaps.push(newMap.id)

                        this.props.firebase.user(authUser.uid).set({
                            relMaps: relMaps
                        }, { merge: true });

                        this.setState({ addingMap: false, renamingMap: false });
                    });
                }
            });
        }

        if (this.state.renamingMap) {
            this.props.firebase.relMap(this.state.renamingId).set({
                name: this.state.newName
            }, { merge: true }).then(() => {
                var newMaps = this.state.relMaps.map(
                    (selMap, i) => selMap.id == this.state.renamingId ? { ...selMap, name: this.state.newName }
                        : selMap
                );

                this.setState({
                    addingMap: false,
                    renamingMap: false,
                    relMaps: newMaps
                });
            });
        }
    }

    selMap(id) {

    }

    delMap(id, name) {
        if (confirm("\u00BFOlvidar " + name + "? (El mapa se perder\u00E1 para siempre)")) {
            const user = this.props.firebase.auth.currentUser;
            this.props.firebase.relMap(id).delete().then(() => {
                this.props.firebase.user(user.uid).get().then((currentUser) => {
                    var relMaps = currentUser.data().relMaps.filter((relMap) => relMap != id);

                    this.props.firebase.user(user.uid).set({
                        relMaps: relMaps
                    }, { merge: true });
                });
            });
        }
    }

    handleNewName(name) {
        this.setState({ newName: name });
    }

    cancelAddMap() {
        this.setState({ addingMap: false, renamingMap: false });
    }

    showAddMap() {
        this.setState({ addingMap: true });
    }

    showRenameMap(id) {
        this.setState({ renamingMap: true, renamingId: id });
    }

    componentDidMount() {
        const authUser = this.props.authUser;
        this.unsubscribe = this.props.firebase.user(authUser.uid).onSnapshot(currentUser => {
            this.setState({ relMaps: [], loading: true });
            if (currentUser.data().relMaps && currentUser.data().relMaps.length > 0) {
                currentUser.data().relMaps.forEach((relMap, index) => {
                    this.props.firebase.relMap(relMap).get().then((doc) => {
                        let mapList = !this.state.relMaps ? [] : [...this.state.relMaps];
                        mapList.push({
                            id: doc.id,
                            name: doc.data().name
                        });

                        this.setState({
                            relMaps: mapList,
                            loading: index < currentUser.data().relMaps.length - 1,
                        });
                    });
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        });

        if (this.props.authUser.roles[ROLES.NARRATOR] == ROLES.NARRATOR) {
            this.setState({ players: [], loadingMaps: true });
            this.props.firebase.users().get().then((users) => {
                var playerList = [];
                users.forEach((user, index) => {
                    if (user.id != authUser.uid) {
                        if (user.data().relMaps && user.data().relMaps.length > 0) {
                            var mapList = [];
                            user.data().relMaps.forEach((relMap, mapIndex) => {
                                this.props.firebase.relMap(relMap).get().then((doc) => {
                                    mapList.push({
                                        id: doc.id,
                                        name: doc.data().name
                                    });

                                    if (mapIndex == user.data().relMaps.length - 1) {
                                        playerList.push({
                                            id: user.id,
                                            name: user.data().username,
                                            relMaps: mapList
                                        });
                                    }

                                    this.setState({
                                        players: playerList,
                                        loadingMaps: index < users.length - 1,
                                    });
                                });
                            });
                        }
                    }

                    this.setState({
                        players: playerList,
                        loadingMaps: index < users.length - 1,
                    });
                });
            });
        }
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {
        const { relMaps, loading, loadingMaps, players, addingMap, renamingMap } = this.state;

        return (
            <div>
                <div className='container'>
                    <h2>Mapas de Relaciones</h2>
                    {loading && <div>Loading ...</div>}

                    <MapList maps={relMaps} delFunction={this.delMap} renFunction={this.showRenameMap} selMap={this.selMap} />
                    <div className='row addButton'>
                        <button className='btn btn-danger' onClick={() => this.showAddMap()}>
                            Agregar
                        </button>
                    </div>
                    {(addingMap || renamingMap) &&
                        <div>
                            Nombre: <input type='text' name='newName' id='newName' value={this.state.newName} onChange={(e) => this.handleNewName(e.target.value)} />
                            <div className='row addButton'>
                                <button className='btn btn-danger' onClick={() => this.addMap()}>
                                    Ok
                                </button>
                                <button className='btn btn-danger' onClick={() => this.cancelAddMap()}>
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    }
                </div>
                <div className='container player-character-list'>
                    {loadingMaps && <div>Loading ...</div>}

                    <PlayerList players={players} selMap={this.selMap} />
                </div>
            </div>
        );
    }
}

const PlayerList = ({ players, selMap }) => (
    players.map(player => (
        <div key={player.id}>
            <h3>{player.name}</h3>
            <ul className='character-list'>
                {player.relMaps.map(relMap => (
                    <li key={relMap.id}>
                        <div className='row'>
                            <div className='col-12'>
                                <Link to={`${ROUTES.MAP}/${relMap.id}`} onClick={() => selMap(relMap.id)}>
                                    {relMap.name}
                                </Link>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    ))
);

const MapList = ({ maps, delFunction, renFunction, selMap }) => (
    <ul className='character-list'>
        {maps.map(relMap => (
            <li key={relMap.id}>
                <div className='row'>
                    <div className='col-7'>
                        <Link to={`${ROUTES.MAP}/${relMap.id}`} onClick={() => selMap(relMap.id)}>
                            {relMap.name}
                        </Link>
                    </div>
                    <div className='col-5'>
                        <button className='btn btn-danger' onClick={() => renFunction(relMap.id)}>
                            <i className='fas fa-edit'></i>
                        </button>
                        <button className='btn btn-danger' onClick={() => delFunction(relMap.id, relMap.name)}>
                            <i className='fas fa-trash'></i>
                        </button>
                    </div>
                </div>
            </li>
        ))}
    </ul>
);

const condition = authUser => !!authUser;

const Maps = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(userStore),
)(MapsBase);

export default Maps;