declare var require: any

var React = require('react');

const TableMortalsTemplate = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-3'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>PLANTILLAS DE MORTALES</div>            
            <table className='alternate-bg table-mortal-templates'>
                <thead>
                    <tr>
                        <th></th>
                        <th>ATRIBUTOS</th>
                        <th>HABILIDADES</th>
                        <th>VENTAJAS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>MORTAL D&Eacute;BIL</td>
                        <td>Dos a 2, el resto a 1.</td>
                        <td>Tres a 2, cinco a 1.</td>
                        <td>Ninguna.</td>
                    </tr>
                    <tr>
                        <td>MORTAL MEDIO</td>
                        <td>Dos a 3, tres a 2, el resto a 1.</td>
                        <td>Tres a 3, cuatro a 2, cinco a 1.</td>
                        <td>Hasta 3 puntos (m&aacute;ximo 2 puntos en Defectos).</td>
                    </tr>
                    <tr>
                        <td>MORTAL DOTADO</td>
                        <td>Uno a 4, dos a 3, dos a 2, el resto a 1.</td>
                        <td>Dos a 4 (una con una Especialidad), cuatro a 3, cuatro a 2, cuatro a 1.</td>
                        <td>Hasta 10 puntos (m&aacute;ximo 4 puntos en Defectos).</td>
                    </tr>
                    <tr>
                        <td>MORTAL LETAL</td>
                        <td>Dos a 5, dos a 4, dos a 3, el resto a 2.</td>
                        <td>Una a 5, tres a 4, cinco a 3, seis a 2, tres Especialidades.</td>
                        <td>Hasta 15 puntos (sin Defectos).</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableMortalsTemplate;