declare var require: any

var React = require('react');

import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../firebase/context';
import { SignUpLink } from './signup';
import { PasswordForgetLink } from './passwordForget';
import * as ROUTES from '../constants/routes';

const SignInPage = () => (
    <div className='container'>
        <h2>Inciar Sesi&oacute;n</h2>
        <SignInForm />
        <PasswordForgetLink />
        <SignUpLink />
    </div>
);

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then((result) => {
                this.setState({ ...INITIAL_STATE });
                if (!result.user.emailVerified) {
                    this.props.history.push(ROUTES.PASSWORD_VERIFY);
                } else {
                    this.props.history.push(ROUTES.HOME);
                }
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;
        const isInvalid = password === '' || email === '';
        return (
            <form onSubmit={this.onSubmit}>
                <div className='row'>
                    <div className='login-col-1'>
                        Correo
                    </div>
                    <div className='login-col-2'>
                        <input
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            placeholder="E-Mail"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='login-col-1'>
                        Contrase&ntilde;a
                    </div>
                    <div className='login-col-2'>
                        <input
                            name="password"
                            value={password}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Contrase&ntilde;a"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='login-col-1'>
                    </div>
                    <div className='login-col-btn'>
                        <button className='btn btn-danger' disabled={isInvalid} type="submit">
                            Iniciar Sesi&oacute;n
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        {error && <p>{error.message}</p>}
                    </div>
                </div>
            </form>
        );
    }
}

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };