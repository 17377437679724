declare var require: any

var React = require('react');

const TableDifficulty = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-4'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>DIFICULTAD DE LAS ACCIONES</div>            
            <table className='alternate-bg table-difficulty'>
                <thead>
                    <tr>
                        <th>DIFICULTAD DE LA ACCI&Oacute;N</th>
                        <th>N&Uacute;MERO DE DIFICULTAD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Rutinaria (golpear a un objetivo est&aacute;tico, convencer a un amigo leal de que te ayude).</td>
                        <td>1 &eacute;xito.</td>
                    </tr>
                    <tr>
                        <td>Sencilla (seducir a alguien que ya tiene ganas, intimidar a un debilucho).</td>
                        <td>2 &eacute;xitos.</td>
                    </tr>
                    <tr>
                        <td>Moderada (reemplazar el sistema de sonido de un coche, caminar por la cuerda floja).</td>
                        <td>3 &eacute;xitos.</td>
                    </tr>
                    <tr>
                        <td>Desafiante (localizar la fuente de un susurro, crear una obra de arte memorable).</td>
                        <td>4 &eacute;xitos.</td>
                    </tr>
                    <tr>
                        <td>Dif&iacute;cil (convencer a un polic&iacute;a de que &eacute;sa no es tu coca&iacute;na, reconstruir el bloque de un motor destrozado).</td>
                        <td>5 &eacute;xitos.</td>
                    </tr>
                    <tr>
                        <td>Muy dif&iacute;cil (correr por la cuerda floja mientras te disparan, calmar a una turba hostil y violenta).</td>
                        <td>6 &eacute;xitos.</td>
                    </tr>
                    <tr>
                        <td>Casi imposible (encontrar a un sintecho en concreto en Los &Aacute;ngeles en una sola noche, recitar un texto largo sin equivocarte en un idioma que no hablas).</td>
                        <td>7 &eacute;xitos o m&aacute;s.</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableDifficulty;