declare var require: any

var React = require('react');

import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes'
import { userStore } from '../store/storeModule'
import { withAuthorization } from '../session/session';
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import { compose } from 'recompose';
import { INITIAL_CHARACTER } from '../reducers/userReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CharactersBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            characters: [],
            loadingPlayers: false,
            players: []
        };

        this.selCharacter = this.selCharacter.bind(this);
        this.delCharacter = this.delCharacter.bind(this);
        this.lockCharacter = this.lockCharacter.bind(this);
    }

    unsubscribe: any;

    addCharacter() {
        const authUser = this.props.authUser;
        //const user = this.props.firebase.auth.currentUser;
        this.props.firebase.user(authUser.uid).get().then((currentUser) => {
            var newCharacter = { ...INITIAL_CHARACTER };
            newCharacter.name = currentUser.data().username;
            newCharacter.owner = currentUser.id;
            this.props.firebase.characters().add(newCharacter).then((newChar) => {
                var characters = {};
                if (currentUser.data().characters) {
                    characters = { ...currentUser.data().characters };
                }

                characters[newChar.id] = null;

                this.props.firebase.user(authUser.uid).set({
                    characters: characters
                }, { merge: true });
            });
        });
    }

    selCharacter(id) {
        let authUser = this.props.authUser;
        this.props.firebase.user(authUser.uid).set({
            currentCharacter: id
        }, { merge: true });
    }

    delCharacter(id, name) {
        const authUser = this.props.authUser;
        if (confirm("\u00BFMandar a " + name + " a torpor? (El personaje se perder\u00E1 para siempre)")) {
            this.props.firebase.user(authUser.uid).get().then((currentUser) => {
                var updatedUser = { ...currentUser.data() };

                delete updatedUser.characters[id];

                this.props.firebase.user(authUser.uid).set(updatedUser).then(() => {
                    this.props.firebase.character(id).delete().then(() => {
                        this.props.firebase.deletePicture(id).catch(() => {
                        });
                    });
                });
            });
        }
    }

    componentDidMount() {
        const authUser = this.props.authUser;
        this.unsubscribe = this.props.firebase.user(authUser.uid).onSnapshot(currentUser => {
            this.setState({ characters: [], loading: true });
            if (Object.keys(currentUser.data().characters).length > 0) {
                Object.keys(currentUser.data().characters).forEach((char, index) => {
                    this.props.firebase.character(char).get().then((doc) => {
                        let charList = !this.state.characters ? [] : [...this.state.characters];
                        charList.push({
                            id: doc.id,
                            name: doc.data().name,
                            clan: doc.data().clan,
                            isLocked: doc.data().isLocked ? true : false
                        });

                        this.setState({
                            characters: charList,
                            loading: index < currentUser.data().characters.length - 1,
                        });
                    });
                });
            } else {
                this.setState({
                    loading: false
                });
            }

            if (currentUser.data().currentChronicle) {
                this.props.firebase.chronicle(currentUser.data().currentChronicle).get().then((chronicle) => {
                    if (chronicle.data().owner = currentUser.id) {
                        let playerList = [];
                        Object.keys(chronicle.data().players).forEach((player, index) => {
                            this.props.firebase.user(player).get().then(playerData => {
                                let charList = [];
                                let promises = [];
                                Object.keys(playerData.data().characters).forEach((playerChar, charIndex) => {
                                    if (playerData.data().characters[playerChar] == chronicle.id) {
                                        promises.push(this.props.firebase.character(playerChar).get());
                                    }
                                });

                                Promise.all(promises).then(values => {
                                    values.forEach((value, charIndex) => {
                                        charList.push({
                                            id: value.id,
                                            name: value.data().name,
                                            clan: value.data().clan,
                                            isLocked: value.data().isLocked ? true : false
                                        });

                                        if (charIndex == values.length - 1) {
                                            playerList.push({
                                                id: playerData.id,
                                                name: playerData.data().username,
                                                characters: charList
                                            });

                                            this.setState({
                                                players: playerList,
                                                loadingPlayers: index < Object.keys(chronicle.data().players).length - 1,
                                            });
                                        }
                                    });
                                });
                            }).catch(error => {
                                console.log('Missing permission for ' + player);
                            });
                        });
                    }
                });
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    lockCharacter(playerId, charId, lock) {
        this.props.firebase.character(charId).set({
            isLocked: lock
        }, { merge: true }).then(() => {
            let newPlayers = Object.assign([], this.state.players.map(
                (player, i) => player.id == playerId ?
                    {
                        ...player,
                        characters: Object.assign([], player.characters.map(
                            (character, j) => character.id == charId ? { ...character, isLocked: lock } : character))
                    } : player));
            this.setState({
                players: newPlayers
            });
        });
    }


    render() {
        const { characters, loading, loadingPlayers, players } = this.state;

        return (
            <div>
                <div className='container characters'>
                    <h2>Personajes</h2>
                    {loading && <div>Loading ...</div>}

                    <CharList characters={characters} delFunction={this.delCharacter} selCharacter={this.selCharacter} />
                    <div className='row addButton'>
                        <button className='btn btn-danger' onClick={() => this.addCharacter()}>
                            Agregar
                        </button>
                    </div>
                </div>
                <div className='container player-character-list'>
                    {loadingPlayers && <div>Loading ...</div>}

                    <PlayerList players={players} selCharacter={this.selCharacter} lockFunction={this.lockCharacter} />
                </div>
            </div>
        );
    }
}

const PlayerList = ({ players, selCharacter, lockFunction }) => (
    <div className='table-responsive admin-table'>
        {players && players.length > 0 &&
            <table className="table">
                <thead>
                    <tr>
                        <th>
                            Nombre
                    </th>
                        <th>
                            Clan
                    </th>
                        <th>
                        </th>
                    </tr>
                </thead>
                {players.map(player => (
                    <tbody key={player.id}>
                        <tr>
                            <td colSpan='3'>
                                <strong>{player.name}</strong>
                            </td>
                        </tr>
                        {player.characters.map(char => (
                            <tr key={char.id}>
                                <td>
                                    <Link to={`${ROUTES.CHARACTER}/${char.id}`} onClick={() => selCharacter(char.id)}>
                                        {char.name}
                                    </Link>
                                </td>
                                <td>
                                    {char.clan}
                                </td>
                                <td>
                                    <button className='btn btn-danger' onClick={() => lockFunction(player.id, char.id, !char.isLocked)}>
                                        <FontAwesomeIcon icon={char.isLocked ? 'lock' : 'lock-open'} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                ))}
            </table>
        }
    </div>
);

const CharList = ({ characters, delFunction, selCharacter }) => (
    <div className='table-responsive admin-table'>
        <table className="table">
            <thead>
                <tr>
                    <th>
                        Nombre
                    </th>
                    <th>
                        Clan
                    </th>
                    <th>
                    </th>
                </tr>
            </thead>
            <tbody>
                {characters.map(char => (
                    <tr key={char.id}>
                        <td>
                            <Link to={`${ROUTES.CHARACTER}/${char.id}`} onClick={() => selCharacter(char.id)}>
                                {char.name}
                            </Link>
                        </td>
                        <td>
                            {char.clan}
                        </td>
                        <td>
                            <button className='btn btn-danger' onClick={() => delFunction(char.id, char.name)}>
                                <i className='fas fa-trash'></i>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const condition = authUser => !!authUser;

const Characters = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(userStore),
)(CharactersBase);

export default Characters;