declare var require: any

var React = require('react');

import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes'
import { userStore, mapStateToUserProps } from '../store/storeModule'
import { connect, Provider } from "react-redux";
import { AuthUserContext, withAuthorization } from '../session/session';
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import { compose } from 'recompose';

class LandingBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        let authUser = this.props.authUser;
        if (authUser) {
            if (authUser.emailVerified) {
                this.props.history.push(ROUTES.CHARACTERS);
            } else {
                this.props.history.push(ROUTES.PASSWORD_VERIFY);
            }
        } else {
            this.props.history.push(ROUTES.SIGN_IN);
        }
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div>
            </div>
        );
    }
}

const condition = authUser => !!authUser;

const Landing = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(userStore),
)(LandingBase);

export default Landing;