declare var require: any

var React = require('react');

const TableFrenzyProvocations = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-12'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>PROVOCACIONES DEL FRENES&Iacute;</div>
            <div className='black-background'>FRENES&Iacute; POR FURIA</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>PROVOCACI&Oacute;N</th>
                        <th>DIFICULTAD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Amigo asesinado</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Amante o Piedra de Toque herida</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Amante o Piedra de Toque asesinada</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>Provocaci&oacute;n o acoso f&iacute;sico</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Insultado por alguien inferior</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Humillaci&oacute;n p&uacute;blica</td>
                        <td>2</td>
                    </tr>
                </tbody>
            </table>
            <div className='black-background'>FRENES&Iacute; POR HAMBRE</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>PROVOCACI&Oacute;N</th>
                        <th>DIFICULTAD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Visi&oacute;n de una herida abierta u olor penetrante a sangre con una Ansia de 4 o m&aacute;s</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Sabor de la sangre con una Ansia de 4 o m&aacute;s</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Fallar un control de Enardecimiento con una Ansia de 5</td>
                        <td>4</td>
                    </tr>
                </tbody>
            </table>
            <div className='black-background'>FRENES&Iacute; POR TERROR</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>PROVOCACI&Oacute;N</th>
                        <th>DIFICULTAD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Hoguera</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>En el interior de un edificio en llamas</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Recibir una quemadura</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Luz del sol velada (a trav&eacute;s de una ventana, etc.)</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Completamente expuesto a luz solar directa</td>
                        <td>4</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableFrenzyProvocations;