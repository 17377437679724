declare var require: any

var React = require('react');

const TableFeeding = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-7'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>ALIMENTARSE</div>            
            <table className='alternate-bg table-difficulty'>
                <thead>
                    <tr>
                        <th>FUENTE</th>
                        <th>ANSIA SACIADA</th>
                        <th>TIEMPO</th>
                        <th>NOTAS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Varios animales peque&ntilde;os (tres o cuatro gatos, una docena de ratas o m&aacute;s)</td>
                        <td>1</td>
                        <td>Una escena</td>
                        <td rowSpan='3'>No sacia el Ansia de vampiros con una Potencia de Sangre de m&aacute;s de 2.<br />Resonancia animal; No causa Discrasia.</td>
                    </tr>
                    <tr>
                        <td>Animal de tama&ntilde;o medio (mapache, perro, coyote)</td>
                        <td>1</td>
                        <td>Un turno</td>
                    </tr>
                    <tr>
                        <td>Animal de tama&ntilde;o medio (mapache, perro, coyote)</td>
                        <td>2</td>
                        <td>Una escena</td>
                    </tr>
                    <tr>
                        <td>Bolsa de sangre</td>
                        <td>1</td>
                        <td>Un turno</td>
                        <td>No sacia el Ansia de vampiros con una Potencia de Sangre de m&aacute;s de 2.<br/>No causa Resonancia ni Discrasia.</td>
                    </tr>
                    <tr>
                        <td>Beber de un humano</td>
                        <td>1</td>
                        <td>Tres turnos</td>
                        <td rowSpan='2'>Incluye lamer la herida para cerrarla.</td>
                    </tr>
                    <tr>
                        <td>M&aacute;ximo que se puede beber de un humano sin da&ntilde;arlo</td>
                        <td>2</td>
                        <td>Una escena</td>
                    </tr>
                    <tr>
                        <td>Beber de un humano hasta da&ntilde;arlo, de forma que corre riesgo de morir sin tratamiento</td>
                        <td>1-4</td>
                        <td>Un turno por punto de Ansia saciado</td>
                        <td>El Ansia saciada equivale a da&ntilde;o agravado; para sobrevivir a la p&eacute;rdida de sangre, el humano tira Fuerza + Resistencia contra una dificultad igual al Ansia saciada.</td>
                    </tr>
                    <tr>
                        <td>Humano vaciado y muerto</td>
                        <td>5</td>
                        <td>Cinco turnos</td>
                        <td>&Uacute;nica manera de alcanzar Ansia 0 (cero).</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableFeeding;