declare var require: any

var React = require('react');

const TableHumor = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-13'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>HUMOR</div>            
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>HUMOR</th>
                        <th>ELEMENTO</th>
                        <th>FUNCI&Oacute;N JUNGUIANA</th>
                        <th>HORMONA</th>
                        <th>EMOCIONES Y CONDICIONES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Col&eacute;rico</td>
                        <td>Fuego</td>
                        <td>Sentimiento</td>
                        <td>Adrenalina</td>
                        <td>Enfadado, violento, abusador, apasionado, envidioso.</td>
                    </tr>
                    <tr>
                        <td>Melanc&oacute;lico</td>
                        <td>Tierra</td>
                        <td>Pensamiento</td>
                        <td>Tiroidea</td>
                        <td>Tristeza, miedo, intelectualidad, depresi&oacute;n, realismo.</td>
                    </tr>
                    <tr>
                        <td>Flem&aacute;tico</td>
                        <td>Agua</td>
                        <td>Intuici&oacute;n</td>
                        <td>Pituitaria</td>
                        <td>Vagancia, apat&iacute;a, calma, control, sentimentalismo..</td>
                    </tr>
                    <tr>
                        <td>Sangu&iacute;neo</td>
                        <td>Aire</td>
                        <td>Sensaci&oacute;n</td>
                        <td>Testosterona / Estr&oacute;geno</td>
                        <td>Excitaci&oacute;n, felicidad, adicci&oacute;n, actividad, capricho, entusiasmo.</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableHumor;