declare var require: any

var React = require('react');

const TableCripplingInjury = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-18'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>HERIDAS INCAPACITANTES</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>DA&Ntilde;O AGRAVADO + TIRADA</th>
                        <th>HERIDA INCAPACITANTE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1-6</td>
                        <td><strong>Aturdido:</strong> Gasta 1 punto de Fuerza de Voluntad o pierde un turno.</td>
                    </tr>
                    <tr>
                        <td>7-8</td>
                        <td><strong>Trauma severo en la cabeza:</strong> Haz las tiradas F&iacute;sicas con un -1; la Mentales, con un -2.</td>
                    </tr>
                    <tr>
                        <td>9-10</td>
                        <td>
                            <strong>Hueso o articulaci&oacute;n rotos:</strong> Haz las tiradas con un -3 al usar el miembro afectado, o<br/>
                            <strong>Cegado:</strong> Haz las tiradas relacionadas con la visi&oacute;n (incluyendo el combate) con un -3.<br />
                            El Narrador decide qu&eacute; tiene m&aacute;s sentido en este combate.
                        </td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td><strong>Lesiones generalizadas:</strong> Haz las tiradas con un -2 y suma +1 a todo el da&ntilde;o adicional sufrido.</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td><strong>Lisiado:</strong> Los mismos efectos que una rotura, pero el miembro se pierde o queda destruido y no puede usarse.</td>
                    </tr>
                    <tr>
                        <td>13+</td>
                        <td>Muerte (mortal) o Letargo inmediato (vampiro).</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableCripplingInjury;