import {
    ADD_DISCIPLINE, SET_ATTRIBUTE, SET_SKILL, SET_CONSUMABLE, GET_CHARACTER, SET_FIELD, SET_DISCIPLINE_VALUE, SET_DISCIPLINE_NAME, LOCK_CHANGE, SET_PICTURE, SET_CHECKBOX, GET_CHRONICLES,
    SET_PROPERTY } from '../actions/actionTypes'

const INITIAL_DATA = {
    chronicles: [],
    character: {
        "name": '',
        "concept": '',
        "chronicle": '',
        "ambition": '',
        "desire": '',
        "predator": '',
        "clan": '',
        "generation": '',
        "sire": '',
        "isLocked": false,
        "attributes": [
            {
                "id": 1,
                "section": "Attributes",
                "parent": "Physical",
                "name": "Strength",
                "value": 1,
                "order": 1
            },
            {
                "id": 2,
                "section": "Attributes",
                "parent": "Physical",
                "name": "Dexterity",
                "value": 1,
                "order": 2
            },
            {
                "id": 3,
                "section": "Attributes",
                "parent": "Physical",
                "name": "Stamina",
                "value": 1,
                "order": 3
            },
            {
                "id": 4,
                "section": "Attributes",
                "parent": "Social",
                "name": "Charisma",
                "value": 1,
                "order": 4
            },
            {
                "id": 5,
                "section": "Attributes",
                "parent": "Social",
                "name": "Manipulation",
                "value": 1,
                "order": 5
            },
            {
                "id": 6,
                "section": "Attributes",
                "parent": "Social",
                "name": "Composure",
                "value": 1,
                "order": 6
            },
            {
                "id": 7,
                "section": "Attributes",
                "parent": "Mental",
                "name": "Intelligence",
                "value": 1,
                "order": 7
            },
            {
                "id": 8,
                "section": "Attributes",
                "parent": "Mental",
                "name": "Wits",
                "value": 1,
                "order": 8
            },
            {
                "id": 9,
                "section": "Attributes",
                "parent": "Mental",
                "name": "Resolve",
                "value": 1,
                "order": 9
            }
        ],
        "skills": [
            {
                "id": 1,
                "section": "Skills",
                "parent": null,
                "name": "Athletics",
                "value": 0,
                "order": 1
            },
            {
                "id": 2,
                "section": "Skills",
                "parent": null,
                "name": "Brawl",
                "value": 0,
                "order": 1
            },
            {
                "id": 3,
                "section": "Skills",
                "parent": null,
                "name": "Craft",
                "value": 0,
                "order": 1
            },
            {
                "id": 4,
                "section": "Skills",
                "parent": null,
                "name": "Drive",
                "value": 0,
                "order": 1
            },
            {
                "id": 5,
                "section": "Skills",
                "parent": null,
                "name": "Firearms",
                "value": 0,
                "order": 1
            },
            {
                "id": 6,
                "section": "Skills",
                "parent": null,
                "name": "Melee",
                "value": 0,
                "order": 1
            },
            {
                "id": 7,
                "section": "Skills",
                "parent": null,
                "name": "Larceny",
                "value": 0,
                "order": 1
            },
            {
                "id": 8,
                "section": "Skills",
                "parent": null,
                "name": "Stealth",
                "value": 0,
                "order": 1
            },
            {
                "id": 9,
                "section": "Skills",
                "parent": null,
                "name": "Survival",
                "value": 0,
                "order": 1
            },
            {
                "id": 10,
                "section": "Skills",
                "parent": null,
                "name": "Animal Ken",
                "value": 0,
                "order": 1
            },
            {
                "id": 11,
                "section": "Skills",
                "parent": null,
                "name": "Etiquette",
                "value": 0,
                "order": 1
            },
            {
                "id": 12,
                "section": "Skills",
                "parent": null,
                "name": "Insight",
                "value": 0,
                "order": 1
            },
            {
                "id": 13,
                "section": "Skills",
                "parent": null,
                "name": "Intimidation",
                "value": 0,
                "order": 1
            },
            {
                "id": 14,
                "section": "Skills",
                "parent": null,
                "name": "Leadership",
                "value": 0,
                "order": 1
            },
            {
                "id": 15,
                "section": "Skills",
                "parent": null,
                "name": "Performance",
                "value": 0,
                "order": 1
            },
            {
                "id": 16,
                "section": "Skills",
                "parent": null,
                "name": "Persuasion",
                "value": 0,
                "order": 1
            },
            {
                "id": 17,
                "section": "Skills",
                "parent": null,
                "name": "Streetwise",
                "value": 0,
                "order": 1
            },
            {
                "id": 18,
                "section": "Skills",
                "parent": null,
                "name": "Subterfuge",
                "value": 0,
                "order": 1
            },
            {
                "id": 19,
                "section": "Skills",
                "parent": null,
                "name": "Academics",
                "value": 0,
                "order": 1
            },
            {
                "id": 20,
                "section": "Skills",
                "parent": null,
                "name": "Awareness",
                "value": 0,
                "order": 1
            },
            {
                "id": 21,
                "section": "Skills",
                "parent": null,
                "name": "Finance",
                "value": 0,
                "order": 1
            },
            {
                "id": 22,
                "section": "Skills",
                "parent": null,
                "name": "Investigation",
                "value": 0,
                "order": 1
            },
            {
                "id": 23,
                "section": "Skills",
                "parent": null,
                "name": "Medicine",
                "value": 0,
                "order": 1
            },
            {
                "id": 24,
                "section": "Skills",
                "parent": null,
                "name": "Occult",
                "value": 0,
                "order": 1
            },
            {
                "id": 25,
                "section": "Skills",
                "parent": null,
                "name": "Politics",
                "value": 0,
                "order": 1
            },
            {
                "id": 26,
                "section": "Skills",
                "parent": null,
                "name": "Science",
                "value": 0,
                "order": 1
            },
            {
                "id": 27,
                "section": "Skills",
                "parent": null,
                "name": "Technology",
                "value": 0,
                "order": 1
            }
        ],
        "health": {
            "id": 1,
            "name": "Health",
            "maxValue": 10,
            "lethalValue": 0,
            "aggravatedValue": 0
        },
        "willpower": {
            "id": 1,
            "name": "Willpower",
            "maxValue": 10,
            "lethalValue": 0,
            "aggravatedValue": 0
        },
        "humanity": {
            "id": 1,
            "name": "Humanity",
            "maxValue": 10,
            "lethalValue": 0,
            "aggravatedValue": 0
        },
        "hunger": {
            "id": 1,
            "name": "Humanity",
            "maxValue": 5,
            "lethalValue": 0,
            "aggravatedValue": 0
        },
        "bloodPotency": {
            "id": 1,
            "section": null,
            "parent": null,
            "name": "BloodPotency",
            "value": 1,
            "order": 1
        },
        "disciplines": []
    }
}

const CharacterReducer = (state = INITIAL_DATA, action) => {
    if (action.type != LOCK_CHANGE && action.type != GET_CHARACTER && state.character.isLocked) {
        return state;
    }

    switch (action.type) {
        case LOCK_CHANGE:
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    isLocked: !state.isLocked
                })
            });
        case ADD_DISCIPLINE:
            let nextId = state.character[action.collection] ? state.character[action.collection].length : 0;
            let newDiscipline = { id: nextId, order: nextId, parent: action.collection, section: action.collection, name: '', value: 0 };
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    [action.collection]: state.character[action.collection] ? state.character[action.collection].concat(newDiscipline) : [].concat(newDiscipline)
                })
            });
        case SET_DISCIPLINE_NAME:
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    [action.collection]: state.character[action.collection].map(
                        (discipline, i) => discipline.id == action.id ? { ...discipline, description: action.name, name: action.name }
                            : discipline
                    )
                })
            });
        case SET_DISCIPLINE_VALUE:
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    [action.collection]: state.character[action.collection].map(
                        (discipline, i) => discipline.id == action.id ? { ...discipline, value: discipline.value == 1 && action.value == 1 ? 0 : action.value }
                            : discipline
                    )
                })
            });
        case SET_ATTRIBUTE:
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    attributes: state.character.attributes.map(
                        (attribute, i) => attribute.id == action.id ? { ...attribute, value: attribute.value == 1 && action.value == 1 ? 0 : action.value }
                            : attribute
                    )
                })
            });
        case SET_PROPERTY:
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    [action.name]: { ...state.character[action.name], value: state.character[action.name].value == 1 && action.value == 1 ? 0 : action.value }
                })
            });
        case SET_FIELD:
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    [action.name]: action.value
                })
            });
        case SET_PICTURE:
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    picture: action.file
                })
            });
        case SET_SKILL:
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    skills: state.character.skills.map(
                        (skill, i) => skill.id == action.id ? { ...skill, value: skill.value == 1 && action.value == 1 ? 0 : action.value }
                            : skill
                    )
                })
            });
            return state
        case SET_CONSUMABLE:
            let maxValue = state.character[action.consumable].maxValue;
            let lethalValue = state.character[action.consumable].lethalValue;
            let aggravatedValue = state.character[action.consumable].aggravatedValue;
            if (action.value == maxValue) {
                if (aggravatedValue == maxValue) {
                    maxValue--;
                    lethalValue = lethalValue.value >= maxValue ? maxValue : action.value;
                    aggravatedValue = aggravatedValue >= maxValue ? maxValue : aggravatedValue;
                } else {
                    if (lethalValue == maxValue) {
                        aggravatedValue = maxValue;
                    } else {
                        lethalValue = maxValue;
                    }
                }
            } else {
                if (action.value == (maxValue + 1)) {
                    maxValue++;
                    lethalValue = lethalValue == maxValue ? maxValue - 1 : lethalValue;
                    aggravatedValue = aggravatedValue == maxValue ? maxValue - 1 : aggravatedValue;
                } else {
                    if (lethalValue == maxValue) {
                        if (action.value > aggravatedValue) {
                            aggravatedValue = action.value;
                        } else {
                            aggravatedValue = action.value == aggravatedValue ? aggravatedValue - 1 : action.value;
                        }
                    } else {
                        if (action.value > lethalValue) {
                            lethalValue = action.value;
                        } else {
                            aggravatedValue = aggravatedValue == action.value ? action.value - 1 : lethalValue == action.value ? action.value : action.value - 1;
                            lethalValue = aggravatedValue == action.value ? aggravatedValue : lethalValue == action.value ? action.value - 1 : action.value;
                        }
                    }
                }
            }

            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    [action.consumable]: {
                        id: state.character[action.consumable].id,
                        name: state.character[action.consumable].name,
                        maxValue: maxValue,
                        lethalValue: lethalValue,
                        aggravatedValue: aggravatedValue
                    }
                })
            });
        case SET_CHECKBOX:
            return Object.assign({}, state, {
                character: Object.assign({}, state.character, {
                    [action.consumable]: {
                        id: state.character[action.consumable].id,
                        name: state.character[action.consumable].name,
                        maxValue: state.character[action.consumable].maxValue,
                        lethalValue: action.value == 1 && state.character[action.consumable].lethalValue == 1 ? 0 : action.value,
                        aggravatedValue: action.value == 1 && state.character[action.consumable].aggravatedValue == 1 ? 0 : action.value
                    }
                })
            });
        case GET_CHARACTER:
            return Object.assign({}, state, {
                character: action.character
            });
        case GET_CHRONICLES:
            return Object.assign({}, state, {
                chronicles: action.chronicles
            });
        default:
            return state
    }
}

export default CharacterReducer