declare var require: any

var React = require('react');

import { withFirebase } from '../firebase/context';
import { withAuthorization } from '../session/session';
import { compose } from 'recompose';

class InviteFormBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            message: null
        }
    }

    sendVerification() {
        this.props.firebase.validEmail(this.state.email).set({
            createdOn: new Date()
        }).then(() => {
            this.setState({ message: 'Se agreg\u00F3 correo autorizado.' });
        }).catch((error) => {
            this.setState({ message: error });
        });
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, message } = this.state;

        return (
            <div className='container email-verify'>
                <div className='row'>
                    <div className='login-col-1'>
                        Correo
                    </div>
                    <div className='login-col-2'>
                        <input
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            placeholder="E-Mail"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <button className='btn btn-danger' onClick={() => this.sendVerification()}>
                            Agregar correo
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        {message && <p>{message}</p>}
                    </div>
                </div>
            </div>
        );
    }
}

const condition = authUser => !!authUser;

const InviteForm = compose(
    withAuthorization(condition),
    withFirebase
)(InviteFormBase);

export default InviteForm;