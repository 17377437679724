declare var require: any

var React = require('react');

const TableRangedCombat = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-16'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>COMBATE A DISTANCIA</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>COBERTURA</th>
                        <th>MODIFICADOR EN DADOS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><strong>Sin cobertura</strong></td>
                        <td>-2</td>
                    </tr>
                    <tr>
                        <td><strong>S&oacute;lo ocultaci&oacute;n</strong> (arbustos, un &aacute;rbol peque&ntilde;o contra balas de calibre de fusil)</td>
                        <td>-1</td>
                    </tr>
                    <tr>
                        <td><strong>Cobertura dura</strong> (el bloque motor de un coche, la esquina de un edificio de hormig&oacute;n)</td>
                        <td>+/- 0</td>
                    </tr>
                    <tr>
                        <td><strong>Atrincheramiento</strong> (sacos de arena, b&uacute;nker militar)</td>
                        <td>+1</td>
                    </tr>
                    <tr>
                        <td><strong>Aspillera</strong> (ranura de disparo de un veh&iacute;culo de combate de infanter&iacute;a)</td>
                        <td>+2</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableRangedCombat;