declare var require: any

var React = require('react');

import axios from 'axios'
import {
    addDiscipline,
    setAttribute,
    setSkill,
    getCharacter,
    setConsumable,
    setField,
    setDisciplineValue,
    setDisciplineName,
    setPicture,
    setCheckbox,
    getChronicles,
    setProperty
} from '../actions/characterActionCreator'
import CharacterHeader from './characterHeader';
import CharacterSummary from './characterSummary';
import CharacterAttributes from './characterAttributes';
import CharacterConsumable from './characterConsumable';
import CharacterSkills from './characterSkills';
import CharacterDisciplines from './characterDisciplines';
import CharacterMeritsFlaws from './characterMeritsFlaws';
import CharacterPicture from './characterPicture';
import CharacterField from './characterField';
import CharacterPrinciples from './characterPrinciples';
import CharacterAttribute from './characterAttribute';
import { withAuthorization } from '../session/session';
import { characterStore, mapStateToCharacterProps } from '../store/storeModule'
import { connect } from "react-redux";
import { compose } from 'recompose';
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import * as _ from 'lodash';

class CharacterBase extends React.Component {
    constructor(props) {
        super(props);

        this.setProfilePicture = this.setProfilePicture.bind(this);
    }

    loading: boolean = false;

    handleClick() {
        axios.get('http://204.236.180.175/VampireCharacter')
            .then((response) => {
                characterStore.dispatch(getCharacter({ character: response.data }));
            });
    }

    addDiscipline() {
        characterStore.dispatch(addDiscipline('disciplines'));
    }

    setDisciplineName(id, name) {
        characterStore.dispatch(setDisciplineName({ id: id, name: name, collection: 'disciplines' }));
    }

    setDisciplineValue(id, value) {
        characterStore.dispatch(setDisciplineValue({ id: id, value: value, collection: 'disciplines' }));
    }

    addMerit() {
        characterStore.dispatch(addDiscipline('merits'));
    }

    setMeritName(id, name) {
        characterStore.dispatch(setDisciplineName({ id: id, name: name, collection: 'merits' }));
    }

    setMeritValue(id, value) {
        characterStore.dispatch(setDisciplineValue({ id: id, value: value, collection: 'merits' }));
    }

    setAttribute(id, value) {
        characterStore.dispatch(setAttribute({ id: id, value: value }));
    }

    setProperty(name, value) {
        characterStore.dispatch(setProperty({ name: name, value: value }));
    }

    setSkill(id, value) {
        characterStore.dispatch(setSkill({ id: id, value: value }));
    }

    setHealth(value) {
        characterStore.dispatch(setConsumable({ consumable: 'health', value: value }));
    }

    setWillPower(value) {
        characterStore.dispatch(setConsumable({ consumable: 'willpower', value: value }));
    }

    setHunger(value) {
        characterStore.dispatch(setCheckbox({ consumable: 'hunger', value: value }));
    }

    setHumanity(value) {
        characterStore.dispatch(setConsumable({ consumable: 'humanity', value: value }));
    }

    setField(name, value) {
        characterStore.dispatch(setField({ name: name, value: value }));
    }

    setProfilePicture(file) {
        const characterId = this.props.match?.params.id;
        this.props.firebase.uploadPicture(characterId, file).then(() => {
            this.getProfilePicture();
        });
    }

    getProfilePicture() {
        const characterId = this.props.match?.params.id;
        this.props.firebase.downloadPicture(characterId).then((downloadURL) => {
            characterStore.dispatch(setPicture(downloadURL));
        }).catch(() => {
            this.props.firebase.downloadPicture('empty_profile.jpg').then((downloadURL) => {
                characterStore.dispatch(setPicture(downloadURL));
            });
        });
    }

    componentDidMount() {
        const characterId = this.props.match?.params.id;
        const authUser = this.props.authUser;
        if (characterId) {
            this.loading = true;
            this.props.firebase.character(characterId).get().then((character) => {
                this.getProfilePicture();
                characterStore.dispatch(getCharacter(character.data()));

                if (authUser.chronicles) {
                    let promises = [];
                    Object.keys(authUser.chronicles).forEach((chronicle) => {
                        promises.push(this.props.firebase.chronicle(chronicle).get());
                    });

                    Promise.all(promises).then(values => {
                        let chronicles = [{ value: '', text: 'Ninguna' }];
                        values.forEach((data, index) => {
                            chronicles.push({
                                value: data.id,
                                text: data.data().name
                            });

                            if (index == values.length - 1) {
                                characterStore.dispatch(getChronicles(chronicles));
                            }
                        });
                    });
                }
                this.loading = false;
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.loading && !_.isEqual(this.props.character, prevProps.character)) {
            const characterId = this.props.match?.params.id;
            this.props.firebase.character(characterId).set(this.props.character).then(() => {
                const authUser = this.props.authUser;
                this.props.firebase.user(authUser.uid).get().then(user => {
                    let characters = { ...this.props.characters };
                    characters[characterId] = this.props.character.chronicle;

                    this.props.firebase.user(authUser.uid).set({
                        characters: characters
                    }, { merge: true });
                });
            });
        }
    }

    render() {
        return (
            <div>
                <div className='container char-picture'>
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <CharacterPicture imageSource={this.props.character?.picture} onSetProfilePicture={this.setProfilePicture} />
                        </div>
                        <div className='col-6 hide-phone'>
                            <CharacterHeader />
                        </div>
                    </div>
                    <input className='hidden' type="file" name="file" onChange={(event) => this.props.onSetProfilePicture(event.target.files[0])} ref={this.fileRef} />
                </div>
                <br />
                <br />
                <CharacterSummary character={this.props.character} onChangeField={this.setField} chronicles={this.props.chronicles} />
                <br />
                <br />
                <CharacterAttributes character={this.props.character} onSetAttribute={this.setAttribute} />
                <br />
                <br />
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12'>
                            <div className='centered'>Salud</div>
                            <div className='centered'><CharacterConsumable consumable={this.props.character?.health} onSetConsumable={this.setHealth} /></div>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <div className='centered'>Fuerza de Voluntad</div>
                            <div className='centered'><CharacterConsumable consumable={this.props.character?.willpower} onSetConsumable={this.setWillPower} /></div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <CharacterSkills character={this.props.character} onSetAttribute={this.setSkill} />
                <br />
                <CharacterDisciplines disciplines={this.props.character?.disciplines} onAddDiscipline={this.addDiscipline}
                    onSetAttribute={this.setDisciplineValue} onChangeField={this.setDisciplineName} />
                <br />
                <div className='container footer-page-1'>
                    <div className='row'>
                        <div className='col-12 col-lg-4 centered margin-left-10'>
                            <CharacterField id='10' label='Resonancia' name='resonance' value={this.props.character?.resonance} onChangeField={this.setField} />
                        </div>
                        <div className='col-12 col-lg-4 centered'>
                            <label>Ansia</label>
                            <CharacterConsumable consumable={this.props.character?.hunger} onSetConsumable={this.setHunger} checkBoxCount='5' />
                        </div>
                        <div className='col-12 col-lg-4 centered'>
                            <label>Humanidad</label>
                            <CharacterConsumable consumable={this.props.character?.humanity} onSetConsumable={this.setHumanity} />
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <CharacterPrinciples character={this.props.character} onChangeField={this.setField} />
                <br />
                <br />
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <div className='row'>
                                <CharacterMeritsFlaws merits={this.props.character?.merits} onAddMerit={this.addMerit}
                                    onSetAttribute={this.setMeritValue} onChangeField={this.setMeritName} />
                            </div>
                            <br />
                            <br />
                            <div className='row notes'>
                                <CharacterField id='22' label='Notas' name='notes' value={this.props.character?.notes}
                                    onChangeField={this.setField} multiline='true' rows='12' />
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row red-label float-left sheet-section'>
                                        <CharacterAttribute key='bloodPotency0' id='bloodPotency' label='Potencia de Sangre' rating={this.props.character?.bloodPotency.value}
                                            onSetAttribute={this.setProperty} checkBoxCount='10' />
                                    </div>
                                </div>
                            </div>
                            <div className='row page-2-fields'>
                                <div className='col-12 col-md-6'>
                                    <CharacterField id='14' label='Arrebato de Sangre' name='bloodSurge' value={this.props.character?.bloodSurge} onChangeField={this.setField} />
                                </div>
                                <div className='col-12 col-md-6'>
                                    <CharacterField id='15' label='Cantidad de reparaci&oacute;n' name='mendAmount' value={this.props.character?.mendAmount} onChangeField={this.setField} />
                                </div>
                            </div>
                            <div className='row page-2-fields'>
                                <div className='col-12 col-md-6'>
                                    <CharacterField id='16' label='Bonificaci&oacute;n al Poder' name='powerBonus' value={this.props.character?.powerBonus} onChangeField={this.setField} />
                                </div>
                                <div className='col-12 col-md-6'>
                                    <CharacterField id='17' label='Repetici&oacute;n de Enardecimientos' name='rouseReroll' value={this.props.character?.rouseReroll} onChangeField={this.setField} />
                                </div>
                            </div>
                            <div className='row page-2-fields'>
                                <div className='col-12 col-md-6'>
                                    <CharacterField id='18' label='Penalizaci&oacute;n a la alimentaci&oacute;n' name='feedingPenalty' value={this.props.character?.feedingPenalty} onChangeField={this.setField} />
                                </div>
                                <div className='col-12 col-md-6'>
                                    <CharacterField id='19' label='Severidad de la Prohibici&oacute;n' name='baneSeverity' value={this.props.character?.baneSeverity} onChangeField={this.setField} />
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className='row experience red-label'>
                                <div className='col-12'>
                                    <CharacterField id='20' label='Experiencia total' name='expTotal' value={this.props.character?.expTotal} onChangeField={this.setField} />
                                </div>
                            </div>
                            <div className='row experience red-label'>
                                <div className='col-12'>
                                    <CharacterField id='21' label='Experiencia gastada' name='expSpent' value={this.props.character?.expSpent} onChangeField={this.setField} />
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className='row last-fields'>
                                <div className='col-12'>
                                    <CharacterField id='23' label='Edad verdadera' name='trueAge' value={this.props.character?.trueAge} onChangeField={this.setField} />
                                </div>
                            </div>
                            <div className='row last-fields'>
                                <div className='col-12'>
                                    <CharacterField id='24' label='Edad aparente' name='apparentAge' value={this.props.character?.apparentAge} onChangeField={this.setField} />
                                </div>
                            </div>
                            <div className='row last-fields'>
                                <div className='col-12'>
                                    <CharacterField id='25' label='Fecha de nacimiento' name='dateBirth' value={this.props.character?.dateBirth} onChangeField={this.setField} />
                                </div>
                            </div>
                            <div className='row last-fields'>
                                <div className='col-12'>
                                    <CharacterField id='26' label='Fecha de defunci&oacute;n' name='dateDeath' value={this.props.character?.dateDeath} onChangeField={this.setField} />
                                </div>
                            </div>
                            <div className='row last-fields'>
                                <div className='col-12'>
                                    <CharacterField id='27' label='Apariencia' name='appearance' value={this.props.character?.appearance} onChangeField={this.setField}
                                        multiline='true' rows='2' cols='48' />
                                </div>
                            </div>
                            <div className='row last-fields'>
                                <div className='col-12'>
                                    <CharacterField id='28' label='Rasgos rese&ntilde;ables' name='features' value={this.props.character?.features} onChangeField={this.setField} />
                                </div>
                            </div>
                            <div className='row last-fields'>
                                <div className='col-12 notes'>
                                    <CharacterField id='29' label='Historia' name='history' value={this.props.character?.history}
                                        onChangeField={this.setField} multiline='true' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*
                <div className='container'>
                    <div className='row addButton'>
                        <button onClick={() => this.handleClick()}>
                            Aleatorio
                    </button>
                    </div>
                </div>
                */}
            </div>
        );
    }
}


const condition = authUser => !!authUser;
const ConnectedCharacter = connect(mapStateToCharacterProps)(CharacterBase);

const Character = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(characterStore),
)(ConnectedCharacter);

export default Character;