declare var require: any

var React = require('react');
var ReactDOM = require('react-dom');

import './vampire.css';
//import 'bootstrap/scss/bootstrap.scss'
import './scss/custom.scss'
import 'bootstrap/dist/js/bootstrap.js';
import '@fortawesome/fontawesome-free/js/all.js'
import {
    HashRouter as Router,
    Route
} from 'react-router-dom';
import Character from './sheets/character'
import Characters from './sheets/characters'
import Chronicles from './chronicles/chronicles'
import Stories from './chronicles/stories'
import Chronicle from './chronicles/chronicle'
import Navigation from './navigation'
import * as ROUTES from './constants/routes';
import Firebase from './firebase/firebase';
import FirebaseContext from './firebase/context';
import SignUpPage from './admin/signup';
import SignIn from './admin/signin';
import PasswordReset from './admin/passwordReset';
import Account from './admin/account';
import Landing from './admin/landing';
import EmailVerification from './admin/emailVerification';
import AdminPage from './admin/admin';
import InviteForm from './admin/invite';
import Maps from './maps/maps';
import TableBuilder from './tables/tableBuilder';
import Map from './maps/map';
import Friends from './friends/friends';
import withAuthentication from './session/withAuthentication';

export class App extends React.Component {
    render() {
        return (
            <FirebaseContext.Provider value={new Firebase()}>
                <AppBase />
            </FirebaseContext.Provider>
        )
    };
};

export class ContentBase extends React.Component {
    render() {
        return (
            <Router>
                <div>
                    <Navigation />
                    <Route exact path={ROUTES.LANDING} component={Landing} />
                    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                    <Route path={ROUTES.CHRONICLES} component={Chronicles} />
                    <Route path={ROUTES.CHRONICLE_ID} component={Chronicle} />
                    <Route path={ROUTES.STORIES} component={Stories} />
                    <Route path={ROUTES.CHARACTERS} component={Characters} />
                    <Route path={ROUTES.CHARACTER_ID} component={Character} />
                    <Route path={ROUTES.SIGN_IN} component={SignIn} />
                    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordReset} />
                    <Route path={ROUTES.HOME} component={Characters} />
                    <Route path={ROUTES.ACCOUNT} component={Account} />
                    <Route path={ROUTES.ADMIN} component={AdminPage} />
                    <Route path={ROUTES.INVITE} component={InviteForm} />
                    <Route path={ROUTES.MAPS} component={Maps} />
                    <Route path={ROUTES.TABLES} component={TableBuilder} />
                    <Route path={ROUTES.MAP_ID} component={Map} />
                    <Route path={ROUTES.PASSWORD_VERIFY} component={EmailVerification} />
                    <Route path={ROUTES.FRIENDS} component={Friends} />
                </div>
            </Router>
        )
    };
};

const AppBase = withAuthentication(ContentBase);

ReactDOM.render(<App />, document.getElementById('root'));