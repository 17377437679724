import { ADD_CHARACTER, DEL_CHARACTER } from '../actions/actionTypes'

const INITIAL_DATA = {
    email: '',
    username: '',
    characters: []
}

export const INITIAL_CHRONICLE = {
    name: '',
    characters: [],
    players: []
}

export const INITIAL_CHARACTER = {
    "id": '',
    "name": '',
    "concept": '',
    "chronicle": '',
    "ambition": '',
    "desire": '',
    "predator": '',
    "clan": '',
    "generation": '',
    "sire": '',
    "attributes": [
        {
            "id": 1,
            "section": "Attributes",
            "parent": "Physical",
            "name": "Strength",
            "value": 1,
            "order": 1
        },
        {
            "id": 2,
            "section": "Attributes",
            "parent": "Physical",
            "name": "Dexterity",
            "value": 1,
            "order": 2
        },
        {
            "id": 3,
            "section": "Attributes",
            "parent": "Physical",
            "name": "Stamina",
            "value": 1,
            "order": 3
        },
        {
            "id": 4,
            "section": "Attributes",
            "parent": "Social",
            "name": "Charisma",
            "value": 1,
            "order": 4
        },
        {
            "id": 5,
            "section": "Attributes",
            "parent": "Social",
            "name": "Manipulation",
            "value": 1,
            "order": 5
        },
        {
            "id": 6,
            "section": "Attributes",
            "parent": "Social",
            "name": "Composure",
            "value": 1,
            "order": 6
        },
        {
            "id": 7,
            "section": "Attributes",
            "parent": "Mental",
            "name": "Intelligence",
            "value": 1,
            "order": 7
        },
        {
            "id": 8,
            "section": "Attributes",
            "parent": "Mental",
            "name": "Wits",
            "value": 1,
            "order": 8
        },
        {
            "id": 9,
            "section": "Attributes",
            "parent": "Mental",
            "name": "Resolve",
            "value": 1,
            "order": 9
        }
    ],
    "skills": [
        {
            "id": 1,
            "section": "Skills",
            "parent": null,
            "name": "Athletics",
            "value": 0,
            "order": 1
        },
        {
            "id": 2,
            "section": "Skills",
            "parent": null,
            "name": "Brawl",
            "value": 0,
            "order": 1
        },
        {
            "id": 3,
            "section": "Skills",
            "parent": null,
            "name": "Craft",
            "value": 0,
            "order": 1
        },
        {
            "id": 4,
            "section": "Skills",
            "parent": null,
            "name": "Drive",
            "value": 0,
            "order": 1
        },
        {
            "id": 5,
            "section": "Skills",
            "parent": null,
            "name": "Firearms",
            "value": 0,
            "order": 1
        },
        {
            "id": 6,
            "section": "Skills",
            "parent": null,
            "name": "Melee",
            "value": 0,
            "order": 1
        },
        {
            "id": 7,
            "section": "Skills",
            "parent": null,
            "name": "Larceny",
            "value": 0,
            "order": 1
        },
        {
            "id": 8,
            "section": "Skills",
            "parent": null,
            "name": "Stealth",
            "value": 0,
            "order": 1
        },
        {
            "id": 9,
            "section": "Skills",
            "parent": null,
            "name": "Survival",
            "value": 0,
            "order": 1
        },
        {
            "id": 10,
            "section": "Skills",
            "parent": null,
            "name": "Animal Ken",
            "value": 0,
            "order": 1
        },
        {
            "id": 11,
            "section": "Skills",
            "parent": null,
            "name": "Etiquette",
            "value": 0,
            "order": 1
        },
        {
            "id": 12,
            "section": "Skills",
            "parent": null,
            "name": "Insight",
            "value": 0,
            "order": 1
        },
        {
            "id": 13,
            "section": "Skills",
            "parent": null,
            "name": "Intimidation",
            "value": 0,
            "order": 1
        },
        {
            "id": 14,
            "section": "Skills",
            "parent": null,
            "name": "Leadership",
            "value": 0,
            "order": 1
        },
        {
            "id": 15,
            "section": "Skills",
            "parent": null,
            "name": "Performance",
            "value": 0,
            "order": 1
        },
        {
            "id": 16,
            "section": "Skills",
            "parent": null,
            "name": "Persuasion",
            "value": 0,
            "order": 1
        },
        {
            "id": 17,
            "section": "Skills",
            "parent": null,
            "name": "Streetwise",
            "value": 0,
            "order": 1
        },
        {
            "id": 18,
            "section": "Skills",
            "parent": null,
            "name": "Subterfuge",
            "value": 0,
            "order": 1
        },
        {
            "id": 19,
            "section": "Skills",
            "parent": null,
            "name": "Academics",
            "value": 0,
            "order": 1
        },
        {
            "id": 20,
            "section": "Skills",
            "parent": null,
            "name": "Awareness",
            "value": 0,
            "order": 1
        },
        {
            "id": 21,
            "section": "Skills",
            "parent": null,
            "name": "Finance",
            "value": 0,
            "order": 1
        },
        {
            "id": 22,
            "section": "Skills",
            "parent": null,
            "name": "Investigation",
            "value": 0,
            "order": 1
        },
        {
            "id": 23,
            "section": "Skills",
            "parent": null,
            "name": "Medicine",
            "value": 0,
            "order": 1
        },
        {
            "id": 24,
            "section": "Skills",
            "parent": null,
            "name": "Occult",
            "value": 0,
            "order": 1
        },
        {
            "id": 25,
            "section": "Skills",
            "parent": null,
            "name": "Politics",
            "value": 0,
            "order": 1
        },
        {
            "id": 26,
            "section": "Skills",
            "parent": null,
            "name": "Science",
            "value": 0,
            "order": 1
        },
        {
            "id": 27,
            "section": "Skills",
            "parent": null,
            "name": "Technology",
            "value": 0,
            "order": 1
        }
    ],
    "health": {
        "id": 1,
        "name": "Health",
        "maxValue": 10,
        "lethalValue": 0,
        "aggravatedValue": 0
    },
    "willpower": {
        "id": 1,
        "name": "Willpower",
        "maxValue": 10,
        "lethalValue": 0,
        "aggravatedValue": 0
    },
    "humanity": {
        "id": 1,
        "name": "Humanity",
        "maxValue": 10,
        "lethalValue": 0,
        "aggravatedValue": 0
    },
    "hunger": {
        "id": 1,
        "name": "Humanity",
        "maxValue": 5,
        "lethalValue": 0,
        "aggravatedValue": 0
    },
    "bloodPotency": {
        "id": 1,
        "section": null,
        "parent": null,
        "name": "BloodPotency",
        "value": 1,
        "order": 1
    },
    "disciplines": []
}

const UserReducer = (state = INITIAL_DATA, action) => {
    switch (action.type) {
        case ADD_CHARACTER:
            return Object.assign({}, state, {
                characters: state.characters.concat(action.id)
            });
        case DEL_CHARACTER:
            var index = state.characters.indexOf(action.id)
            return Object.assign({}, state, {
                characters: this.state.characters.filter((character) => character != action.id)
            });
        default:
            return state
    }
}

export default UserReducer