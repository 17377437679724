declare var require: any

var React = require('react');

import { PasswordForgetForm } from './passwordForget';
import PasswordChangeForm from './passwordReset';
import { withAuthorization } from '../session/session';
import CharacterPicture from '../sheets/characterPicture'
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import { compose } from 'recompose';

class AccountPageBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSource: null,
            onSetProfilePicture: null
        };

        this.setProfilePicture = this.setProfilePicture.bind(this);
    }

    setProfilePicture(file) {
        const authUser = this.props.authUser;
        this.props.firebase.uploadProfilePicture(authUser.uid, file).then(() => {
            this.getProfilePicture();
        });
    }

    getProfilePicture() {
        const authUser = this.props.authUser;
        this.props.firebase.downloadProfilePicture(authUser.uid).then((downloadURL) => {
            this.setState({ imageSource: downloadURL });
        }).catch(() => {
            this.props.firebase.downloadProfilePicture('empty_profile.jpg').then((downloadURL) => {
                this.setState({ imageSource: downloadURL });
            });
        });
    }

    componentDidMount() {
        this.getProfilePicture();
    }

    render() {
        return (
            <div className='account container'>
                <h2>Mi Cuenta</h2>
                <CharacterPicture imageSource={this.state.imageSource} onSetProfilePicture={this.setProfilePicture} />
                <PasswordChangeForm />
            </div>
        )
    }
};

const condition = authUser => !!authUser;

const AccountPage = compose(
    withAuthorization(condition),
    withFirebase
)(AccountPageBase);

export default AccountPage;
