declare var require: any

var React = require('react');

export default class WoDCheckBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            value: null,
            onClicked: null
        };
    }

    handleClick() {
        switch (this.props.value) {
            case null:
                this.setState({
                    value: '/'
                });
                break;
            case '/':
                this.setState({
                    value: 'X'
                });
                break;
            case 'X':
                this.setState({
                    value: '.'
                });
                break;
            case '.':
                this.setState({
                    value: null
                });
                break;
        }
    }

    getClass() {
        if (this.props.value == '.') {
            return 'wodCheckBoxBlack';
        } else {
            return 'wodCheckBox';
        }

    }

    render() {
        return (
            <button className={this.getClass()}
                onClick={() => this.props.onClicked(this.props.id)}>
                {this.props.value}
            </button>
        );
    }
}