declare var require: any

var React = require('react');

const TableBloodPotency = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-9'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>POTENCIA DE SANGRE</div>            
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>POTENCIA DE SANGRE</th>
                        <th>ARREBATO DE SANGRE</th>
                        <th>DA&Ntilde;O REPARADO (POR CONTROL DE ENARDECIMIENTO)</th>
                        <th>BONIFICACI&Oacute;N AL PODER DE LAS DISCIPLINAS</th>
                        <th>REPETICI&Oacute;N DE CONTROLES DE ENARDECIMIENTO EN DISCIPLINAS</th>
                        <th>SEVERIDAD DE PERDICI&Oacute;N</th>
                        <th>PENALIZACI&Oacute;N A LA ALIMENTACI&Oacute;N</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>0</td>
                        <td>Ninguno</td>
                        <td>1 punto de da&ntilde;o superficial</td>
                        <td>Ninguna</td>
                        <td>Ninguno</td>
                        <td>0</td>
                        <td>Sin efecto.</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Suma 1 dado</td>
                        <td>1 punto de da&ntilde;o superficial</td>
                        <td>Ninguna</td>
                        <td>Nivel 1</td>
                        <td>1</td>
                        <td>Sin efecto.</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Suma 1 dado</td>
                        <td>2 puntos de da&ntilde;o superficial</td>
                        <td>Suma 1 dado</td>
                        <td>Nivel 1</td>
                        <td>1</td>
                        <td>La sangre envasada y de animales sacia la mitad del Ansia.</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Suma 2 dados</td>
                        <td>2 puntos de da&ntilde;o superficial</td>
                        <td>Suma 1 dado</td>
                        <td>Nivel 2 o menor</td>
                        <td>2</td>
                        <td>La sangre envasada y de animales no sacia el Ansia.</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Suma 2 dados</td>
                        <td>3 puntos de da&ntilde;o superficial</td>
                        <td>Suma 2 dados</td>
                        <td>Nivel 2 o menor</td>
                        <td>2</td>
                        <td>La sangre envasada y de animales no sacia el Ansia.<br/>Reduce 1 punto menos de Ansia por cada humano.</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Suma 3 dados</td>
                        <td>3 puntos de da&ntilde;o superficial</td>
                        <td>Suma 2 dados</td>
                        <td>Nivel 3 o menor</td>
                        <td>3</td>
                        <td>La sangre envasada y de animales no sacia el Ansia.<br />Reduce 1 punto menos de Ansia por cada humano.<br />Debe vaciar y matar a un humano para reducir el Ansia por debajo de 2.</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Suma 3 dados</td>
                        <td>3 puntos de da&ntilde;o superficial</td>
                        <td>Suma 3 dados</td>
                        <td>Nivel 3 o menor</td>
                        <td>3</td>
                        <td rowSpan='2'>La sangre envasada y de animales no sacia el Ansia.<br />Reduce 2 puntos menos de Ansia por cada humano.<br />Debe vaciar y matar a un humano para reducir el Ansia por debajo de 2.</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Suma 4 dados</td>
                        <td>3 puntos de da&ntilde;o superficial</td>
                        <td>Suma 3 dados</td>
                        <td>Nivel 4 o menor</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Suma 4 dados</td>
                        <td>4 puntos de da&ntilde;o superficial</td>
                        <td>Suma 4 dados</td>
                        <td>Nivel 4 o menor</td>
                        <td>4</td>
                        <td rowSpan='2'>La sangre envasada y de animales no sacia el Ansia.<br />Reduce 2 puntos menos de Ansia por cada humano.<br />Debe vaciar y matar a un humano para reducir el Ansia por debajo de 3.</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Suma 5 dados</td>
                        <td>4 puntos de da&ntilde;o superficial</td>
                        <td>Suma 4 dados</td>
                        <td>Nivel 5 o menor</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Suma 5 dados</td>
                        <td>5 puntos de da&ntilde;o superficial</td>
                        <td>Suma 5 dados</td>
                        <td>Nivel 5 o menor</td>
                        <td>5</td>
                        <td>La sangre envasada y de animales no sacia el Ansia.<br />Reduce 3 puntos menos de Ansia por cada humano.<br />Debe vaciar y matar a un humano para reducir el Ansia por debajo de 3.</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableBloodPotency;