// @flow

import styled from 'styled-components'

export default styled.div`
  display: inline-block;
  width: calc(${props => `26px + 20px * ${props.level}`});
  text-align: right;
  color: ${props => props.theme[props.currentTheme].decal};
  font-size: 14px;
  padding-right: 2px;
`
