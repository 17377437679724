declare var require: any

var React = require('react');

const TableDice = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-1'>
        <div className='item-content'>
            <div className='table-col-1a'>
                <img className='table-img-1' src='/images/dice_success.png' />
                <img className='table-img-1' src="/images/dice_critical.png" />
            </div>
            <div className='table-col-2a'>
                <div className='red-label'>DADOS NORMALES</div>
                <div>&#8226; = (1-5) Fallo.</div>
                <div><img className='table-img-small' src="/images/dice_success.png" /> = (6-9) &Eacute;xito.</div>
                <div><img className='table-img-small' src="/images/dice_critical.png" /> = (10) &Eacute;xito, posible victoria cr&iacute;tica</div>
                <div>cada par de <img className='table-img-small' src="/images/dice_critical.png" /> cuenta como cuatro &eacute;xitos).</div>
            </div>
            <div>
                <hr className='table-separator' />
            </div>
            <div className='table-col-1b'>
                <img className='table-img-2' src='/images/dice_bestial_fail.png' />
                <img className='table-img-1' src='/images/dice_hunger_success.png' />
                <img className='table-img-1' src="/images/dice_messy_critical.png" />
            </div>
            <div className='table-col-2b'>
                <div className='red-label'>DADOS DE ANSIA</div>
                <div><img className='table-img-small' src="/images/dice_bestial_fail.png" /> = (1) Fallo, fallo bestial si se falla la prueba.</div>
                <div><span className='red-label'>&#8226;</span> = (2-5) Fallo.</div>
                <div><img className='table-img-small' src="/images/dice_hunger_success.png" /> = (6-9) &Eacute;xito.</div>
                <div><img className='table-img-small' src="/images/dice_messy_critical.png" /> = (10) &Eacute;xito, cr&iacute;tico conflictivo si es parte de una victoria cr&iacute;tica.</div>
                <div>Los dados de ansia nunca pueden volverse a lanzar usando</div>
                <div>Fuerza de Voluntad.</div>
            </div>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableDice;