declare var require: any

var React = require('react');

import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes'
import * as ROLES from '../constants/roles'
import { userStore, mapStateToUserProps } from '../store/storeModule'
import { connect, Provider } from "react-redux";
import { AuthUserContext, withAuthorization } from '../session/session';
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import { compose } from 'recompose';
import { INITIAL_CHRONICLE } from '../reducers/userReducer'

class ChroniclesBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            chronicles: [],
            addingChronicle: false,
            newName: '',
            invitations: [],
            loadingInvitations: false
        };

        this.selChronicle = this.selChronicle.bind(this);
        this.delChronicle = this.delChronicle.bind(this);

        this.acceptInvite = this.acceptInvite.bind(this);
        this.rejectInvite = this.rejectInvite.bind(this);
    }

    unsubscribe: any;
    unsubscribeInvitations: any;

    showAddChronicle() {
        this.setState({ addingChronicle: true });
    }

    cancelAddChronicle() {
        this.setState({ addingChronicle: false });
    }

    handleNewName(name) {
        this.setState({ newName: name });
    }

    selChronicle(id) {
        let authUser = this.props.authUser;
        this.props.firebase.user(authUser.uid).set({
            currentChronicle: id
        }, { merge: true });
    }

    addChronicle() {
        const authUser = this.props.authUser;
        this.props.firebase.user(authUser.uid).get().then((currentUser) => {
            var newChronicle = { ...INITIAL_CHRONICLE };
            newChronicle.name = this.state.newName;
            newChronicle.owner = authUser.uid;
            this.props.firebase.chronicles().add(newChronicle).then((newChronicle) => {
                var chronicles = {};
                if (currentUser.data().chronicles) {
                    chronicles = { ...currentUser.data().chronicles };
                }

                chronicles[newChronicle.id] = ROLES.NARRATOR

                this.props.firebase.user(authUser.uid).set({
                    chronicles: chronicles
                }, { merge: true });

                this.props.firebase.story(newChronicle.id).set({
                    //children: 0,
                    name: this.state.newName,
                    parent: null,
                    root: null,
                    owner: authUser.uid,
                    children: [],
                    story: ''
                });

                this.setState({ newName: '', addingChronicle: false });
            });
        });
    }

    delChronicle(id, name) {
        if (confirm("\u00BFEliminar " + name + "? (La cr\u00F3nica se perder\u00E1 para siempre)")) {
            const authUser = this.props.authUser;
            this.props.firebase.user(authUser.uid).get().then((currentUser) => {
                this.props.firebase.chronicle(id).delete().then(() => {
                    var updatedUser = { ...currentUser.data() };

                    delete updatedUser.chronicles[id];

                    this.props.firebase.user(authUser.uid).set(updatedUser).then(() => {
                        this.props.firebase.character(id).delete().then(() => {
                            this.deleteStory(id);
                        });
                    });
                });
            });
        }
    }

    async deleteStory(id) {
        const authUser = this.props.authUser;
        return new Promise((resolve, reject) => {
            this.props.firebase.story(id).get().then(async value => {
                this.props.firebase.childrenStoriesByParent(authUser.uid, id).get().then(async snapshot => {
                    let promises = [];
                    snapshot.forEach((child) => {
                        promises.push(this.deleteStory(child.id));
                    });

                    return Promise.all(promises);
                }).then(snapshots => {
                    resolve(this.props.firebase.story(id).delete());
                });
            });
        });
    }

    acceptInvite(id) {
        const authUser = this.props.authUser;
        this.props.firebase.invitations(authUser.uid).get().then((invitations) => {
            this.props.firebase.user(authUser.uid).get().then((currentUser) => {
                this.props.firebase.userRef(invitations.data()[id].owner).get().then((owner) => {
                    let chronicles = currentUser.data().chronicles ? {
                        ...currentUser.data().chronicles
                    } : {};

                    chronicles[id] = ROLES.PLAYER;

                    let friends = currentUser.data().friends ? { ...currentUser.data().friends } : {};

                    friends[owner.id] = owner.data().email;

                    this.props.firebase.user(authUser.uid).set({
                        chronicles: chronicles,
                        friends: friends
                    }, { merge: true }).then(() => {
                        let filtered = { ...invitations.data() };
                        delete filtered[id];

                        this.props.firebase.invitations(authUser.uid).set(filtered);
                    });
                });;
            });
        });;
    }

    rejectInvite(id) {
        const authUser = this.props.authUser;
        this.props.firebase.invitations(authUser.email).get().then((invitations) => {
            let filtered = { ...invitations.data() };
            delete filtered[id];

            this.props.firebase.invitations(authUser.email).set(filtered);
        });
    }

    componentDidMount() {
        const authUser = this.props.authUser;
        //const user = this.props.firebase.auth.currentUser;
        this.unsubscribe = this.props.firebase.user(authUser.uid).onSnapshot(currentUser => {
            this.setState({ chronicles: [], loading: true });
            if (currentUser.data().chronicles && Object.keys(currentUser.data().chronicles).length > 0) {
                Object.keys(currentUser.data().chronicles).forEach((chronicle, index) => {
                    this.props.firebase.chronicle(chronicle).get().then((doc) => {
                        let chronicleList = !this.state.chronicles ? [] : [...this.state.chronicles];
                        if (doc.exists) {
                            chronicleList.push({
                                id: doc.id,
                                name: doc.data().name,
                                role: currentUser.data().chronicles[doc.id]
                            });
                        }

                        this.setState({
                            chronicles: chronicleList,
                            loading: index < Object.keys(currentUser.data().chronicles).length - 1,
                        });
                    }).catch((error) => {
                        console.log(error);
                        /*
                        let chronicleList = !this.state.chronicles ? [] : this.state.chronicles.filter(c => c != chronicle);
                        this.props.firebase.user(authUser.uid).set({
                            chronicles: chronicleList
                        }, { merge: true });
                        */
                    });
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        });

        this.unsubscribeInvitations = this.props.firebase.invitations(authUser.uid).onSnapshot(userInvitations => {
            this.setState({ invitations: [], loadingInvitations: true });
            if (userInvitations.exists && Object.keys(userInvitations.data()).length > 0) {
                Object.keys(userInvitations.data()).forEach((chronicle, index) => {
                    let invList = !this.state.invitations ? [] : [...this.state.invitations];
                    invList.push({
                        id: chronicle,
                        name: userInvitations.data()[chronicle].name
                    });

                    this.setState({
                        invitations: invList,
                        loadingInvitations: index < Object.keys(userInvitations.data()).length - 1,
                    });
                });
            } else {
                this.setState({
                    loadingInvitations: false
                });
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
        this.unsubscribeInvitations();
    }


    render() {
        const { chronicles, loading, addingChronicle, loadingInvitations, invitations } = this.state;

        return (
            <div>
                <div className='container chronicles'>
                    <h2>Cr&oacute;nicas</h2>
                    {loading && <div>Loading ...</div>}

                    <ChronicleList chronicles={chronicles} delFunction={this.delChronicle} selChronicle={this.selChronicle} />
                    <div className='row addButton'>
                        <button className='btn btn-danger' onClick={() => this.showAddChronicle()}>
                            Agregar
                        </button>
                    </div>
                    {addingChronicle &&
                        <div>
                            <br />
                            Nombre: <input type='text' name='chronicleName' id='chronicleName' value={this.state.newName} onChange={(e) => this.handleNewName(e.target.value)} />
                            <div className='row addButton'>
                                <button className='btn btn-danger' onClick={() => this.addChronicle()}>
                                    Ok
                                </button>
                                <button className='btn btn-danger' onClick={() => this.cancelAddChronicle()}>
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    }

                    <br />
                    <br />
                    {invitations.length > 0 && <h2>Invitaciones</h2>}
                    {loadingInvitations && <div>Loading ...</div>}
                    <InvitationsList invitations={invitations} acceptFunction={this.acceptInvite} rejectFunction={this.rejectInvite} />
                </div>
            </div>
        );
    }
}

const ChronicleList = ({ chronicles, delFunction, selChronicle }) => (
    <ul className='character-list'>
        {chronicles.map(chron => (
            <li key={chron.id}>
                <div className='row'>
                    <div className='col-11'>
                        <Link to={chron.role == ROLES.NARRATOR ? `${ROUTES.CHRONICLE}/${chron.id}` : ROUTES.CHARACTERS} onClick={() => selChronicle(chron.id)}>
                            {chron.name}
                        </Link>
                    </div>
                    {chron.role == ROLES.NARRATOR &&
                        <div className='col-1'>
                            <button className='btn btn-danger' onClick={() => delFunction(chron.id, chron.name)}>
                                <i className='fas fa-trash'></i>
                            </button>
                        </div>
                    }
                </div>
            </li>
        ))}
    </ul>
);

const InvitationsList = ({ invitations, acceptFunction, rejectFunction }) => (
    <ul className='character-list'>
        {invitations.map(invitation => (
            <li key={invitation.id}>
                <div className='row'>
                    <div className='col-7'>
                        {invitation.name}
                    </div>
                    <div className='col-5'>
                        <button className='btn btn-danger' onClick={() => acceptFunction(invitation.id)}>
                            <i className='fas fa-check'></i>
                        </button>
                        <button className='btn btn-danger' onClick={() => rejectFunction(invitation.id)}>
                            <i className='fas fa-times'></i>
                        </button>
                    </div>
                </div>
            </li>
        ))}
    </ul>
);

const condition = authUser => !!authUser;

const Chronicles = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(userStore),
)(ChroniclesBase);

export default Chronicles;