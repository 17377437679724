declare var require: any

var React = require('react');

import { userStore } from '../store/storeModule'
import { withAuthorization } from '../session/session';
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import { compose } from 'recompose';
import * as MESSAGES from '../constants/messages.js'

class FriendsBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            friends: []
        };

        this.delFriend = this.delFriend.bind(this);
    }

    unsubscribe: any;

    componentDidMount() {
        if (this.props.authUser.friends) {
            Object.keys(this.props.authUser.friends).forEach((friend) => {
                this.props.firebase.user(friend).get().then((user) => {
                    let friendsList = !this.state.friends ? [] : [...this.state.friends];
                    friendsList.push({
                        id: user.id,
                        name: user.data().username,
                        email: user.data().email,
                        pending: false
                    });

                    this.setState({ friends: friendsList });
                }).catch((error) => {
                    let friendsList = !this.state.friends ? [] : [...this.state.friends];
                    friendsList.push({
                        id: friend,
                        name: MESSAGES.PENDING,
                        email: this.props.authUser.friends[friend],
                        pending: true
                    });

                    this.setState({ friends: friendsList });                    
                });;
            });
        }
    }

    async getFriendName(id) {
        var name = ''
        await this.props.firebase.user(id).get().then((friend) => {
            name = friend.data().username
        });

        return name;
    }

    delFriend(id, name) {
        if (confirm("\u00BFEliminar " + name + "?")) {
            const authUser = this.props.authUser;
            this.props.firebase.user(authUser.uid).get().then(user => {
                let friendsList = { ...user.data().friends };
                delete friendsList[id];

                this.props.firebase.user(authUser.uid).set({
                    friends: friendsList
                }, { merge: true });

                let stateList = !this.state.friends ? [] : this.state.friends.filter(f => f.id != id);

                this.setState({ friends: stateList });
            });
        };
    }

    render() {
        return (
            <div>
                <div className='container friends'>
                    <h2>Amigos</h2>
                    <FriendsList players={this.state.friends} delFunction={this.delFriend} />
                </div>
            </div>
        );
    }
}

const FriendsList = ({ players, delFunction }) => (
    <div className='table-responsive admin-table'>
        <table className="table">
            <thead>
                <tr>
                    <th>
                        Nombre
                    </th>
                    <th>
                        E-Mail
                    </th>
                    <th>
                    </th>
                </tr>
            </thead>
            <tbody>
                {players.map(player => (
                    <tr key={player.id}>
                        <td>
                            {player.name}
                        </td>
                        <td>
                            {player.email}
                        </td>
                        <td>
                            <button className='btn btn-danger' onClick={() => delFunction(player.id, player.name)}>
                                <i className='fas fa-trash'></i>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);


const condition = authUser => !!authUser;

const Friends = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(userStore),
)(FriendsBase);

export default Friends;