// @flow

export const getAncestorsByParent: Function = (
    nodes: any,
    currentContainer: any
): NodeList => {
    const foundList: any[] = []
    const search = currentParent => {
        nodes.forEach(n => {
            if (
                !foundList.includes(n.id) &&
                n.parentId === currentParent &&
                n.id !== currentParent
            ) {
                foundList.push(n.id)
                search(n.id)
            }
        })
    }
    search(currentContainer.id)

    return nodes.filter(n => foundList.includes(n.id))
}

export const getAllAncestorsForCurrentContainers = (
    nodeList: any,
    containerItems: any
): Array<any> => {
    return containerItems.map(parent => {
        return getAncestorsByParent(nodeList, parent)
    })
}

export const getChildrenByParent = (
    nodes: any,
    parentId?: number | string
): any => {    
    return nodes.filter(n => n.parentId === parentId).sort((a, b) => { return a.order - b.order })
}
