declare var require: any

var React = require('react');

export default class CharacterPicture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSource: null,
            onSetProfilePicture: null
        };

        this.fileRef = React.createRef()
        this.fileBrowse = this.fileBrowse.bind(this);
    }

    fileRef: any;

    onChangeHandler = event => {
        console.log(event.target.files[0])
    }

    fileBrowse() {
        this.fileRef.current.click()
    }

    render() {
        return (
            <div className='char-picture'>
                <div className='row'>
                    <div className='char-picture-img col-6'>
                        <img src={this.props.imageSource} />
                    </div>
                    <div className='col-6 margin-auto'>
                        <button className='btn btn-danger' onClick={this.fileBrowse}>
                            Cambiar Imagen
                        </button>
                    </div>
                </div>
                <input className='hidden' type="file" name="file" onChange={(event) => this.props.onSetProfilePicture(event.target.files[0])} ref={this.fileRef} />
            </div>
        );
    }
}