declare var require: any

var React = require('react');

const TableCriticalOptions = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-6'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>OPCIONES DE CR&Iacute;TICOS CONFLICTIVOS</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> Adquirir una o m&aacute;s M&aacute;culas.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> Ruptura de la Mascarada.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> Perder 1 punto de Ventaja.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> Fallar la prueba.</div>
            <hr className='table-separator-2' />
            <div className='red-label table-builder-red-header'>OPCIONES DE FALLOS BESTIALES</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> Compulsi&oacute;n.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> Perder 1 punto de Ventaja.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> Sufrir 1 o m&aacute;s puntos de da&ntilde;o agravado.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> Incrementar el Ansia en 1.</div>
            <hr className='table-separator-2' />
            <div className='red-label table-builder-red-header'>COMPULSIONES ALEATORIAS</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> 1-3: Ansia.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> 4-5: Supremac&iacute;a.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> 6-7: Da&ntilde;o.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> 8-9: Paranoia.</div>
            <div className='critical-div'><span className='big-char red-label'>&#9632;</span> 0: Compulsi&oacute;n de Clan*.</div>
            <div className='critical-div'>* Repetir la tirada para Caitiff y Sangre D&eacute;bil.</div>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableCriticalOptions;