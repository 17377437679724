declare var require: any

var React = require('react');

import CharacterAttributeFree from './characterAttributeFree';

export default class CharacterDisciplines extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disciplines: [],
            onAddDiscipline: null,
            onSetAttribute: null,
            onChangeField: null
        };
    }

    handleClick() {
        let nextId = 'discipline' + this.state.disciplines.length;
        let newDiscipline = { name: nextId, description: '', rating: 0 };
        let disciplines = this.state.disciplines.concat(newDiscipline);
        this.setState({ disciplines });
    }

    renderDisciplines() {
        return this.props.disciplines?.map(discipline => {
            return (
                <div key={'disciplines' + discipline.id} className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                    <CharacterAttributeFree key={'disciplines' + discipline.id} id={discipline.id} name={discipline.name} rating={discipline.value}
                        onSetAttribute={this.props.onSetAttribute} onChangeField={this.props.onChangeField} />
                </div>
            );
        });
    }

    render() {
        return (
            <div className='container disciplines' >
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 sectionHeader'>
                        DISCIPLINAS
                        <hr />
                    </div>
                </div>
                <div className='row'>
                    {this.renderDisciplines()}
                </div>
                <div className='row addButton'>
                    <button className='btn btn-danger' onClick={this.props.onAddDiscipline}>
                        Agregar Disciplina
                    </button>
                </div>
            </div>
        );
    }
}