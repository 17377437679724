declare var require: any

var React = require('react');

import createEngine, {
    DefaultLinkModel,
    DefaultNodeModel,
    DiagramModel,
    DiagramEngine
} from '@projectstorm/react-diagrams';
import {
    CanvasWidget
} from '@projectstorm/react-canvas-core';
import DefaultState from './defaultState'
import { connect } from "react-redux";
import { compose } from 'recompose';
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import { withAuthorization } from '../session/session';
import { characterStore, mapStateToCharacterProps } from '../store/storeModule'

class MapBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            model: DiagramModel,
            engine: DiagramEngine,
            newName: ''
        };

        this.state.engine = createEngine();

        this.addNode = this.addNode.bind(this);
        this.renameLink = this.renameLink.bind(this);


        this.state.model = new DiagramModel();
        this.state.engine.setModel(this.state.model);

        this.state.engine.getStateMachine().pushState(new DefaultState());
    }

    addNode() {
        const newNode = new DefaultNodeModel({
            name: this.state.newName,
            color: 'rgb(0,192,255)',
        });

        newNode.setPosition(100, 100);
        newNode.addOutPort('');
        //newNode.addInPort('', false);

        this.state.model.addAll(newNode);

        this.state.engine.repaintCanvas();
    }

    renameLink() {
        this.state.model.getSelectionEntities().filter((s) => s.options.selected).forEach((selected) => {
            if (selected.labels) {
                if (selected.labels.length == 0) {
                    selected.addLabel(this.state.newName);
                } else {
                    selected.labels.forEach((label) => {
                        label.options.label = this.state.newName
                    });
                }
            } else {
                if (selected.options) {
                    selected.options.name = this.state.newName
                }
            }
        });

        this.state.engine.repaintCanvas();
    }

    componentDidMount() {
        const mapId = this.props.match?.params.id;
        this.props.firebase.relMap(mapId).get().then((relMap) => {
            var model = new DiagramModel();            
            model.deserializeModel(JSON.parse(relMap.data().model), this.state.engine);
            this.state.engine.setModel(model);
            this.state.engine.repaintCanvas();
            this.setState({ model: model });
        });
    }

    changeNewNodeName(name) {
        this.setState({ newName: name });
    }

    componentWillUnmount() {
        const mapId = this.props.match?.params.id;
        var str = JSON.stringify(this.state.model.serialize());
        this.props.firebase.relMap(mapId).set({
            model: str
        }, { merge: true })
    }

    render() {
        return (
            <div>
                <div className='map-toolbox'>
                    <div className='table'>
                        <div className='row'>
                            <input type='text' name='newNodeName' id='newNodeName' value={this.state.newName} onChange={(e) => this.changeNewNodeName(e.target.value)} />
                            <button className='btn btn-danger' onClick={this.addNode}>
                                Agregar
                            </button>                            
                            <button className='btn btn-danger' onClick={this.renameLink}>
                                Renombrar
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='relationship-map'>
                        <CanvasWidget engine={this.state.engine} />
                    </div>
                </div>
            </div>
        );
    }
}

const condition = authUser => !!authUser;
const ConnectedMap = connect(mapStateToCharacterProps)(MapBase);

const Map = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(characterStore),
)(ConnectedMap);

export default Map;