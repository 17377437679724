declare var require: any

var React = require('react');

export default class CharacterFieldCombo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            name: null,
            label: null,
            value: '',
            onChangeField: null,
            options: []
        };
    }

    handleChange(e) {
        this.props.onChange(this.props.name, e.target.value);
    }

    render() {        
        return (
            <div className='charAttribute character-field'>
                <label>{this.props.label}</label>
                <select id={'cbo' + this.props.name} onChange={e => this.props.onChangeField(this.props.name, e.target.value)} value={this.props.value}>
                    {this.props.options && this.props.options.map(option => (
                        <option key={'cbo' + this.props.name + option.value} value={option.value}>{option.text}</option>
                    ))}
                </select>
            </div>
        );
    }
}