declare var require: any

var React = require('react');

const TableRandomTemperament = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-15'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>TEMPERAMENTO ALEATORIO Y RESONANCIA</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>TEMPERAMENTO ALEATORIO</th>
                        <th>RESONANCIA ALEATORIA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1-5: Resonancia equilibrada, insignificante.</td>
                        <td>1-3: Flem&aacute;tica.</td>
                    </tr>
                    <tr>
                        <td>6-8: Ef&iacute;mero.</td>
                        <td>4-6: Melanc&oacute;lica.</td>
                    </tr>
                    <tr>
                        <td>9-0: Intenso, potencialmente agudo; tira otra vez y consulta los resultados abajo.</td>
                        <td>7-8: Col&eacute;rica.</td>
                    </tr>
                    <tr>
                        <td>1-8: Intenso; 9-0: Agudo.</td>
                        <td>9-0: Sangu&iacute;nea.</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableRandomTemperament;