declare var require: any

var ReactCanvasCore = require('@projectstorm/react-canvas-core');

import { MouseEvent } from 'react';
import {
    SelectingState,
    Action,
    InputType,
    ActionEvent,
    DragCanvasState
} from '@projectstorm/react-canvas-core';
import { PortModel, DiagramEngine, DragDiagramItemsState, PointModel } from '@projectstorm/react-diagrams-core';
import { CreateLinkState } from './createLinkState';

const startingState = {
    name: 'starting-state'
}

export default class DefaultState extends ReactCanvasCore.State<DiagramEngine> {
    dragCanvas: DragCanvasState;
    createLink: CreateLinkState;
    dragItems: DragDiagramItemsState;

    constructor() {
	   super(startingState);
	   this.childStates = [new SelectingState()];
	   this.dragCanvas = new DragCanvasState();
	   this.createLink = new CreateLinkState();
	   this.dragItems = new DragDiagramItemsState();

	   // determine what was clicked on
	   this.registerAction(
		  new Action({
			 type: InputType.MOUSE_DOWN,
			 fire: (event: ActionEvent<MouseEvent>) => {
				const element = this.engine.getActionEventBus().getModelForEvent(event);

				// the canvas was clicked on, transition to the dragging canvas state
				if (!element) {
				    this.transitionWithEvent(this.dragCanvas, event);
				}
				// initiate dragging a new link
				else if (element instanceof PortModel) {
				    return;
				}
				else if (element instanceof PointModel) {
				    event.event.defaultPrevented = true;
				    return;
				}
				// move the items (and potentially link points)
				else {
				    this.transitionWithEvent(this.dragItems, event);
				}
			 }
		  })
	   );

	   this.registerAction(
		  new Action({
			 type: InputType.MOUSE_UP,
			 fire: (event: ActionEvent<MouseEvent>) => {
				const element = this.engine.getActionEventBus().getModelForEvent(event);

				if (element instanceof PortModel) this.transitionWithEvent(this.createLink, event);
			 }
		  })
	   );
    }
}