declare var require: any

var React = require('react');

export default class CharacterHeader extends React.Component {
    render() {
        return (
            <div>
                <div className='sectionHeader'>
                    <h1>VAMPIRO</h1>
                    <h5>LA MASCARADA</h5>
                </div>
            </div>
        );
    }
}