declare var require: any

var React = require('react');

import { withFirebase } from '../firebase/context';
import { withAuthorization } from '../session/session';
import * as ROLES from '../constants/roles';
import { compose } from 'recompose';

class AdminPageBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            users: [],
        };

        this.delUser = this.delUser.bind(this);
    }

    delUser(id, name) {
        if (confirm("\u00BFEliminar " + name + "?")) {
            this.props.firebase.user(id).get().then((user) => {
                user.data().characters.forEach((char) => {
                    this.props.firebase.character(char).delete();
                });

                Object.keys(user.data().chronicles).forEach((chronicle) => {
                    if (user.data().chronicles[chronicle] == ROLES.NARRATOR) {
                        this.deleteStory(chronicle).then(() => {
                            this.props.firebase.chronicle(chronicle).delete();
                        });
                    }
                });

                user.data().relMaps.forEach((relMap) => {
                    this.props.firebase.relMap(relMap).delete();
                });

                this.props.firebase.user(id).delete();
            });
        }
    }

    async deleteStory(id) {
        return new Promise((resolve, reject) => {
            this.props.firebase.story(id).get().then(async value => {
                this.props.firebase.childrenStoriesByParent(id).get().then(async snapshot => {
                    let promises = [];
                    snapshot.forEach((child) => {
                        promises.push(this.deleteStory(child.id));
                    });

                    return Promise.all(promises);
                }).then(snapshots => {
                    resolve(this.props.firebase.story(id).delete());
                });
            });
        });
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.users().onSnapshot(snapshot => {
            var usersList = [];
            snapshot.forEach(function (doc) {
                usersList.push(doc.data());
            });


            this.setState({
                users: usersList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        //this.props.firebase.users().off();
    }

    render() {
        const { users, loading } = this.state;

        return (
            <div className='container'>
                <h2>Admin</h2>

                {loading && <div>Loading ...</div>}

                <UserList users={users} delFunction={this.delUser} />
            </div>
        );
    }
}

const UserList = ({ users, delFunction }) => (
    <div className='table-responsive admin-table'>
        <table className="table">
            <thead>
                <tr>
                    <th>
                        E-Mail
                    </th>
                    <th>
                        Nombre
                    </th>
                    <th>
                    </th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => (
                    <tr key={user.id}>
                        <td>
                            {user.email}
                        </td>
                        <td>
                            {user.username}
                        </td>
                        <td>
                            <button className='btn btn-danger' onClick={() => delFunction(user.id, user.username)}>
                                <i className='fas fa-trash'></i>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const condition = authUser =>
    authUser && !!authUser.roles[ROLES.ADMIN];

const AdminPage = compose(
    withAuthorization(condition),
    withFirebase,
)(AdminPageBase);

export default AdminPage;