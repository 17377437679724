declare var require: any

var React = require('react');

import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes'
import * as ROLES from '../constants/roles'
import { userStore, mapStateToUserProps } from '../store/storeModule'
import { connect, Provider } from "react-redux"
import { AuthUserContext, withAuthorization } from '../session/session'
import { withFirebase } from '../firebase/context'
import { withStore } from '../store/withStore'
import { compose } from 'recompose'
import Tree from '../tree/Tree'
import { Editor } from '@tinymce/tinymce-react';
import { slide as Menu } from 'react-burger-menu'

class StoriesBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stories: [],
            storyText: '',
            newName: '',
            currentStory: '',
            isArchived: false,
            deletedStories: 0,
            menuOpen: true,
            authorized: false,
            showArchive: false
        };

        this.onSelectNode = this.onSelectNode.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleNewName = this.handleNewName.bind(this);
        this.setStoryBody = this.setStoryBody.bind(this);
        this.setStoryName = this.setStoryName.bind(this);
        this.deleteStoryPrompt = this.deleteStoryPrompt.bind(this);
        this.archiveStoryPrompt = this.archiveStoryPrompt.bind(this);
        this.showArchiveChange = this.showArchiveChange.bind(this);
    }

    unsubscribe: any;

    componentDidMount() {
        const authUser = this.props.authUser;
        this.props.firebase.user(authUser.uid).get().then(currentUser => {
            const chronicle = currentUser.data().currentChronicle;
            if (!chronicle || currentUser.data().chronicles[chronicle] == ROLES.PLAYER) {
                this.props.history.push(ROUTES.CHRONICLES);
            } else {
                this.unsubscribe = this.props.firebase.story(chronicle).onSnapshot((snapshot) => {
                    let items = [];
                    items.push({
                        id: snapshot.id,
                        parentId: null,
                        label: snapshot.data().name,
                        items: null,
                        order: 1
                    });

                    if (snapshot.data().children) {
                        let promises = [];
                        snapshot.data().children.forEach((story) => {
                            promises.push(this.props.firebase.story(story).get());
                        });

                        Promise.all(promises).then(children => {
                            children.forEach(child => {
                                if (!child.data().archived || this.state.showArchive) {
                                    items.push({
                                        id: child.id,
                                        parentId: child.data().parent,
                                        label: child.data().name,
                                        items: null,
                                        order: child.data().order
                                    });

                                    items.push({
                                        id: 'addStoryTo' + child.id,
                                        parentId: child.id,
                                        label: 'Nueva Historia',
                                        isAddNew: true,
                                        order: Number.MAX_SAFE_INTEGER
                                    });
                                }
                            });

                            items.push({
                                id: 'addStoryTo' + snapshot.id,
                                parentId: snapshot.id,
                                label: 'Nueva Historia',
                                isAddNew: true,
                                order: Number.MAX_SAFE_INTEGER
                            });

                            this.setState({
                                stories: items,
                                authorized: true,
                                currentStory: this.state.currentStory ?? snapshot.id,
                                newName: this.state.currentStory ? this.state.newName : snapshot.data().name
                            });
                        });
                    } else {
                        items.push({
                            id: 'addStoryTo' + snapshot.id,
                            parentId: snapshot.id,
                            label: 'Nueva Historia',
                            isAddNew: true
                        });

                        this.setState({
                            stories: items,
                            authorized: true
                        });
                    }
                });
            }
        });
    }

    onSelectNode(node) {
        if (node.isAddNew) {
            const chronicle = this.props.authUser.currentChronicle;
            const authUser = this.props.authUser;
            const newOrder = this.state.stories.filter(s => s.parentId == node.parentId).length;
            this.props.firebase.stories().add({
                //children: 0,
                name: 'Nueva',
                parent: node.parentId,
                root: chronicle,
                owner: authUser.uid,
                children: [],
                story: '',
                order: newOrder
            }).then((story) => {
                this.props.firebase.story(chronicle).get().then((rootStory) => {
                    let newChildren = rootStory.data().children ? [...rootStory.data().children] : [];
                    newChildren.push(story.id);

                    this.props.firebase.story(chronicle).set({
                        children: newChildren
                    }, { merge: true });

                    this.setState({ newName: 'Nueva', currentStory: story.id, storyText: '', menuOpen: false });
                });
            });
        } else {
            this.props.firebase.story(node.id).get().then((story) => {
                this.setState({
                    newName: story.data().name, currentStory: story.id, isArchived: story.data().archived,
                    storyText: story.data().story ? story.data().story : '', menuOpen: false
                });
            });
        }
    }

    handleEditorChange(content, editor) {
        this.setState({ storyText: content });
    }

    setStoryBody(e) {
        if (this.state.currentStory) {
            this.props.firebase.story(this.state.currentStory).set({
                story: e.target.getContent()
            }, { merge: true });
        }
    }

    deleteStoryPrompt() {
        const currentStory = this.state.currentStory;
        if (this.state.currentStory) {
            if (confirm("\u00BFEliminar la historia? (Se perder\u00E1 para siempre)")) {
                const chronicle = this.props.authUser.currentChronicle;
                this.deleteStory(this.state.currentStory).then(() => {
                    this.props.firebase.story(chronicle).get().then((rootStory) => {
                        let items = this.state.stories.filter(story => story.id != currentStory && !this.isStoryChildOf(story.id, currentStory));
                        let children = rootStory.data().children.filter(story => items.filter(s => s.id == story).length > 0);

                        this.setState({ newName: rootStory.data().name, currentStory: rootStory.id, storyText: rootStory.data().story, stories: items });

                        this.props.firebase.story(chronicle).set({
                            children: children
                        }, { merge: true });
                    });
                });
            }
        }
    }

    archiveStoryPrompt(isArchived) {
        const currentStory = this.state.currentStory;
        if (this.state.currentStory) {
            const promptText = isArchived ? "\u00BFDesarchivar la historia?" : "\u00BFArchivar la historia?"
            if (confirm(promptText)) {
                const chronicle = this.props.authUser.currentChronicle;
                this.archiveStory(this.state.currentStory, isArchived).then(() => {
                    if (!this.state.showArchive) {
                        this.props.firebase.story(chronicle).get().then((rootStory) => {
                            let items = this.state.stories.filter(story => story.id != currentStory && !this.isStoryChildOf(story.id, currentStory));

                            this.setState({
                                newName: rootStory.data().name, currentStory: rootStory.id, storyText: rootStory.data().story, stories: items,
                                isArchived: !isArchived
                            });
                        });
                    } else {
                        this.setState({ isArchived: !isArchived });
                    }
                });
            }
        }
    }

    isStoryChildOf(storyId, storyParent) {
        const story = this.state.stories.filter(s => s.id == storyId)[0];
        if (story) {
            if (story.parentId != null) {
                if (story.parentId == storyParent) {
                    return true;
                } else {
                    return this.isStoryChildOf(story.parentId, storyParent);
                }
            } else {
                return false;
            }
        }
    }

    async deleteStory(id) {
        const authUser = this.props.authUser;
        return new Promise((resolve, reject) => {
            this.props.firebase.story(id).get().then(async value => {
                this.props.firebase.childrenStoriesByParent(authUser.uid, id).get().then(async snapshot => {
                    let promises = [];
                    snapshot.forEach((child) => {
                        promises.push(this.deleteStory(child.id));
                    });

                    return Promise.all(promises);
                }).then(snapshots => {
                    resolve(this.props.firebase.story(id).delete());                    
                });
            });
        });
    }

    async archiveStory(id, isArchived) {
        const authUser = this.props.authUser;
        return new Promise((resolve, reject) => {
            this.props.firebase.story(id).get().then(async value => {
                this.props.firebase.childrenStoriesByParent(authUser.uid, id).get().then(async snapshot => {
                    let promises = [];
                    snapshot.forEach((child) => {
                        promises.push(this.archiveStory(child.id, isArchived));
                    });

                    return Promise.all(promises);
                }).then(snapshots => {
                    resolve(this.props.firebase.story(id).set({
                        archived: !isArchived
                    }, { merge: true }));
                });
            });
        });
    }

    handleNewName(name) {
        this.setState({ newName: name });
    }

    setStoryName(name) {
        if (this.state.currentStory) {
            const chronicle = this.props.authUser.currentChronicle;
            this.props.firebase.story(this.state.currentStory).set({
                name: name
            }, { merge: true });

            let items = this.state.stories.map(
                (story) => story.id == this.state.currentStory ? { ...story, label: name } : story
            );

            this.setState({
                stories: items
            });
        }
    }

    componentWillUnmount() {
        if (this.unsubscribe)
            this.unsubscribe();
    }

    isMenuOpen = state => {
        if (state.isOpen === this.state.menuOpen)
            return;

        this.setState({ menuOpen: state.isOpen });
    };

    showArchiveChange() {
        this.setState({ showArchive: !this.state.showArchive });        
        this.unsubscribe();
        this.componentDidMount();
    }

    render() {
        const { authorized } = this.state;
        return (
            <div>
                {
                    authorized &&
                    <StoryBase menuOpen={this.state.menuOpen} isMenuOpen={this.isMenuOpen} stories={this.state.stories} onSelectNode={this.onSelectNode}
                        newName={this.state.newName} setStoryName={this.setStoryName} isArchived={this.state.isArchived} showArchive={this.showArchive}
                        handleNewName={this.handleNewName} deleteStoryPrompt={this.deleteStoryPrompt} archiveStoryPrompt={this.archiveStoryPrompt}
                        storyText={this.state.storyText} handleEditorChange={this.handleEditorChange} setStoryBody={this.setStoryBody}
                        showArchiveChange={this.showArchiveChange} />
                }
            </div>
        )
    };
}

const StoryBase = ({ menuOpen, isMenuOpen, stories, onSelectNode, newName, isArchived, setStoryName, handleNewName, deleteStoryPrompt, archiveStoryPrompt,
    storyText, handleEditorChange, setStoryBody, showArchive, showArchiveChange }) => (
        <div className='stories'>
            <div className='row rich-editor'>
                <div className='col checkbox-container'>
                    <input className='form-control checkbox' type="checkbox" id="showArchive" name="showArchive" value={showArchive} onChange={showArchiveChange} />
                    <div className='checkbox-label'>Mostrar Archivo</div>
                </div>
            </div>
            <div className='row rich-editor'>
                <Menu isOpen={menuOpen} onStateChange={isMenuOpen}>
                    <Tree nodes={stories} onSelect={onSelectNode} />
                </Menu>
                <div className='col-12'>
                    <div className='row'>
                        <div className='title'>
                            T&iacute;tulo:
                            </div>
                        <div className='col'>
                            <input className='form-control full-width' type='text' name='chronicleName' id='chronicleName' value={newName}
                                onChange={(e) => handleNewName(e.target.value)} onBlur={(e) => setStoryName(e.target.value)} />
                        </div>
                        <div className='delete-button'>
                            <button className='btn btn-danger' onClick={() => archiveStoryPrompt(isArchived)}>
                                {isArchived ? "Desarchivar" : "Archivar"}
                            </button>
                        </div>
                        <div className='delete-button'>
                            <button className='btn btn-danger' onClick={() => deleteStoryPrompt()}>
                                Eliminar
                            </button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <Editor
                                value={storyText}
                                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image',
                                        'charmap print preview anchor help',
                                        'searchreplace visualblocks code',
                                        'insertdatetime media table paste wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
                                }}
                                onEditorChange={handleEditorChange}
                                onBlur={setStoryBody}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

const condition = authUser => !!authUser;

const Stories = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(userStore),
)(StoriesBase);

export default Stories;