declare var require: any

var React = require('react');

import { withFirebase } from '../firebase/context';

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null,
};

class PasswordChangeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { passwordOne } = this.state;
        this.props.firebase
            .doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { passwordOne, passwordTwo, error } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo || passwordOne === '';

        return (
            <form onSubmit={this.onSubmit}>
                <div className='row'>
                    <div className='login-col-1'>
                        Nueva Contrase&ntilde;a
                    </div>
                    <div className='login-col-2'>
                        <input
                            name="passwordOne"
                            value={passwordOne}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Nueva Contrase&ntilde;a"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='login-col-1'>
                    </div>
                    <div className='login-col-2'>
                        <input
                            name="passwordTwo"
                            value={passwordTwo}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Confirma Contrase&ntilde;a"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='login-col-1'>
                    </div>
                    <div className='login-col-btn'>
                        <button className='btn btn-danger' disabled={isInvalid} type="submit">
                            Cambiar contrase&ntilde;a
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        {error && <p>{error.message}</p>}
                    </div>
                </div>
            </form>
        );
    }
}

export default withFirebase(PasswordChangeForm);