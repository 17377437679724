declare var require: any

var React = require('react');

const TableWeaponDamage = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-17'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>DA&Ntilde;O DE ARMAS</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>ARMA</th>
                        <th>PUNTUACI&Oacute;N DE DA&Ntilde;O</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><strong>Arma improvisada,</strong> estaca*</td>
                        <td>+0</td>
                    </tr>
                    <tr>
                        <td><strong>Impacto ligero</strong> (pu&ntilde;o americano)</td>
                        <td>+1</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Impacto fuerte</strong> (porra, maza, barreta, bate de b&eacute;isbol)<br />
                            <strong>Perforaci&oacute;n ligera</strong> (virote de ballesta, navaja autom&aacute;tica)<br />
                            <strong>Disparo ligero</strong> (pistola de 5,6 mm)
                        </td>
                        <td>+2</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Arma de cuerpo a cuerpo pesada</strong> (espada ancha, hacha de bombero)<br />
                            <strong>Disparo medio</strong> (rifle de 7,6 mm (un disparo), pistola de 9 mm, disparo de escopeta a alcance efectivo)
                        </td>
                        <td>+3</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Disparo fuerte</strong> (escopeta de calibre 12 (s&oacute;lo a corta distancia), 9 x 33 mm)<br />
                            <strong>Arma de cuerpo a cuerpo enorme</strong> (claymore, viga de acero)
                        </td>
                        <td>+4</td>
                    </tr>
                    <tr>
                        <td colSpan='2'>*Si un atacante con una estaca tiene &eacute;xito en un ataque dirigido al coraz&oacute;n de un vampiro e inflige 5 o m&aacute;s puntos de da&ntilde;o, la estaca atraviesa el coraz&oacute;n del vampiro y &eacute;ste queda paralizado.</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableWeaponDamage;