declare var require: any

var React = require('react');

import CharacterField from './characterField';

export default class CharacterPrinciples extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            character: null,
            onChangeField: null
        };
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='11' label='Principios de la cr&oacute;nica' name='tenets' value={this.props.character?.tenets}
                            onChangeField={this.props.onChangeField} multiline='true'/>
                    </div>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='12' label='Piedras de Toque y Convicciones' name='touchstones' value={this.props.character?.touchstones}
                            onChangeField={this.props.onChangeField} multiline='true' />
                    </div>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='13' label='Prohibici&oacute;n de Clan' name='clanBane' value={this.props.character?.clanBane}
                            onChangeField={this.props.onChangeField} multiline='true' />
                    </div>
                </div>
            </div>
        );
    }
}