declare var require: any

var React = require('react');

import CharacterAttribute from './characterAttribute';

export default class CharacterSkills extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            character: null,
            onSetAttribute: null
        };
    }

    findById(id) {
        if (this.props.character.skills) {
            return this.props.character.skills.find((element) => {
                return element.id === id;
            })
        } else {
            return undefined;
        }
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 sectionHeader'>
                        HABILIDADES
                        <hr />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                        <CharacterAttribute id='5' label='Armas de Fuego' name='' rating={this.findById(5)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='3' label='Artesan&iacute;a' name='' rating={this.findById(3)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='1' label='Atletismo' name='' rating={this.findById(1)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='4' label='Conducir' name='' rating={this.findById(4)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='7' label='Latrocinio' name='' rating={this.findById(7)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='2' label='Pelea' name='' rating={this.findById(2)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='6' label='Pelea con Armas' name='' rating={this.findById(6)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='8' label='Sigilo' name='' rating={this.findById(8)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='9' label='Supervivencia' name='' rating={this.findById(9)?.value} onSetAttribute={this.props.onSetAttribute} />
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                        <CharacterAttribute id='17' label='Callejeo' name='' rating={this.findById(17)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='11' label='Etiqueta' name='' rating={this.findById(11)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='15' label='Interpretaci&oacute;n' name='' rating={this.findById(15)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='13' label='Intimidaci&oacute;n' name='' rating={this.findById(13)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='14' label='Liderazgo' name='' rating={this.findById(14)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='12' label='Perspicacia' name='' rating={this.findById(12)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='16' label='Persuasi&oacute;n' name='' rating={this.findById(16)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='18' label='Subterfugio' name='' rating={this.findById(18)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='10' label='Trato con Animales' name='' rating={this.findById(10)?.value} onSetAttribute={this.props.onSetAttribute} />
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                        <CharacterAttribute id='19' label='Academicismo' name='Academics' rating={this.findById(19)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='26' label='Ciencias' name='Science' rating={this.findById(26)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='20' label='Consciencia' name='Awareness' rating={this.findById(20)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='21' label='Finanzas' name='Finance' rating={this.findById(21)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='22' label='Investigaci&oacute;n' name='Investigation' rating={this.findById(22)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='23' label='Medicina' name='Medicine' rating={this.findById(23)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='24' label='Ocultismo' name='Occult' rating={this.findById(24)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='25' label='Pol&iacute;tica' name='Politics' rating={this.findById(25)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='27' label='Tecnolog&iacute;a' name='Technology' rating={this.findById(27)?.value} onSetAttribute={this.props.onSetAttribute} />
                    </div>
                </div>
            </div>
        );
    }
}