declare var require: any

var React = require('react');

import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../firebase/context';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';

const SignUpPage = () => (
    <div className='container'>
        <h2>Registro</h2>
        <SignUpForm />
    </div>
);

const INITIAL_STATE = {
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    isAdmin: false,
    error: null,
};

class SignUpFormBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { username, email, passwordOne } = this.state;
        const roles = {};
        const campaigns = {};
        const characters = [];
        const friends = [];
        roles[ROLES.PLAYER] = ROLES.PLAYER;

        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                // Create a user in your Firebase realtime database
                return this.props.firebase
                    .doAddUser({
                        id: authUser.user.uid,
                        currentChronicle: null,
                        currentCharacter: null,
                        username,
                        email,
                        roles,
                        campaigns,
                        characters,
                        friends
                    });
            })
            .then(authUser => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.LANDING);
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            username,
            email,
            passwordOne,
            passwordTwo,
            error,
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            username === '';

        return (
            <form onSubmit={this.onSubmit}>
                <div className='row'>
                    <div className='login-col-1'>
                        Nombre
                    </div>
                    <div className='login-col-2'>
                        <input
                            name="username"
                            value={username}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Nombre"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='login-col-1'>
                        Correo
                    </div>
                    <div className='login-col-2'>
                        <input
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            placeholder="E-Mail"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='login-col-1'>
                        Contrase&ntilde;a
                    </div>
                    <div className='login-col-2'>
                        <input
                            name="passwordOne"
                            value={passwordOne}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Contrase&ntilde;a"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='login-col-1'>                        
                    </div>
                    <div className='login-col-2'>
                        <input
                            name="passwordTwo"
                            value={passwordTwo}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Confirma contrase&ntilde;a"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='login-col-1'>
                    </div>
                    <div className='login-col-btn'>
                        <button className='btn btn-danger' disabled={isInvalid} type="submit">
                            Registro
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        {error && <p>{error.message}</p>}
                    </div>
                </div>
            </form>
        );
    }
}

const SignUpLink = () => (
    <p>
        &iquest;No tienes cuenta? <Link to={ROUTES.SIGN_UP}>Registro</Link>
    </p>
);

const SignUpForm = compose(
    withRouter,
    withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };