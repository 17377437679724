declare var require: any

var React = require('react');

const TableAntagonists = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-5'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>ANTAGONISTAS SENCILLOS</div>            
            <table className='alternate-bg table-difficulty table-antagonists'>                
                <tbody>
                    <tr>
                        <td><strong>Insignificante: </strong>(Mortal inexperto o enfermizo)</td>
                        <td>Dificultad 1</td>
                    </tr>
                    <tr>
                        <td><strong>D&eacute;bil: </strong>(Mortal medio, mat&oacute;n o polic&iacute;a de barrio, otro profesional no destacable)</td>
                        <td>Dificultad 2</td>
                    </tr>
                    <tr>
                        <td><strong>Medio: </strong>(Mortal dotado o Ghoul, agente entrenado o sicario de la mafia, Sangre D&eacute;bil reci&eacute;n Abrazado)</td>
                        <td>Dificultad 3</td>
                    </tr>
                    <tr>
                        <td><strong>Desafiante: </strong>(Neonato, Sangre D&eacute;bil motivado, Ghoul Anciano, operativo de la Segunda Inquisici&oacute;n)</td>
                        <td>Dificultad 4</td>
                    </tr>
                    <tr>
                        <td><strong>Fuerte: </strong>(Ancilla, comandante de la Segunda Inquisici&oacute;n, hombre lobo joven)</td>
                        <td>Dificultad 5-6</td>
                    </tr>
                    <tr>
                        <td><strong>Apabullante: </strong>(Antiguo, hombre lobo adulto)</td>
                        <td>Dificultad 7+</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)} onTouchEnd={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableAntagonists;