import {
    ADD_DISCIPLINE, SET_ATTRIBUTE, SET_SKILL, GET_RANDOM, SET_CONSUMABLE, SET_FIELD, GET_CHARACTER, SET_DISCIPLINE_VALUE, SET_DISCIPLINE_NAME, SET_PICTURE, SET_CHECKBOX, GET_CHRONICLES,
    SET_PROPERTY } from './actionTypes'

let disciplineId = 2

export const addDiscipline = collection => ({
    type: ADD_DISCIPLINE,
    collection
})

export const setDisciplineName = discipline => ({
    type: SET_DISCIPLINE_NAME,
    id: discipline.id,
    name: discipline.name,
    collection: discipline.collection
})

export const setDisciplineValue = discipline => ({
    type: SET_DISCIPLINE_VALUE,
    id: discipline.id,
    value: discipline.value,
    collection: discipline.collection
})

export const setAttribute = attribute => ({
    type: SET_ATTRIBUTE,
    id: attribute.id,
    value: attribute.value
})

export const setProperty = attribute => ({
    type: SET_PROPERTY,
    name: attribute.name,
    value: attribute.value
})

export const setSkill = attribute => ({
    type: SET_SKILL,
    id: attribute.id,
    value: attribute.value
})

export const getRandom = character => ({
    type: GET_RANDOM,
    character: character
})

export const setConsumable = payload => ({
    type: SET_CONSUMABLE,
    consumable: payload.consumable,
    value: payload.value
})

export const setCheckbox = payload => ({
    type: SET_CHECKBOX,
    consumable: payload.consumable,
    value: payload.value
})

export const setField = payload => ({
    type: SET_FIELD,
    name: payload.name,
    value: payload.value
})

export const changeLock = () => ({
    type: LOCK_CHANGE
})

export const getCharacter = character => ({
    type: GET_CHARACTER,
    character
})

export const getChronicles = chronicles => ({
    type: GET_CHRONICLES,
    chronicles
})

export const setPicture = file => ({
    type: SET_PICTURE,
    file
})

export const updateFirebase = payload => (
    () => {
        var action;
        switch (payload.action) {
            case SET_FIELD:
                setField(payload.payload);
                break;
        }
        
        payload.firebase.character(payload.id).set(getState().character);
    }
)