import TableDice from './table_dice.spa';
import TableTraitCost from './table_traitCost.spa';
import TableMortalsTemplate from './table_mortalsTemplate.spa';
import TableDifficulty from './table_difficulty.spa';
import TableAntagonists from './table_antagonists.spa';
import TableCriticalOptions from './table_criticalOptions.spa';
import TableFeeding from './table_feeding.spa';
import TableGeneration from './table_generation.spa';
import TableBloodPotency from './table_bloodPotency.spa';
import TableHumanity from './table_humanity.spa';
import TableTouchstoneDamage from './table_touchstoneDamage.spa';
import TableFrenzyProvocations from './table_frenzyProvocations.spa';
import TableHumor from './table_humor.spa';
import TableResonance from './table_resonance.spa';
import TableRandomTemperament from './table_randomTemperament.spa';
import TableRangedCombat from './table_rangedCombat.spa';
import TableWeaponDamage from './table_weaponDamage.spa';
import TableCripplingInjury from './table_cripplingInjury.spa';
import TableArmor from './table_armor.spa';
import TableSocialDamage from './table_socialDamage.spa';
import TableHuntingGround from './table_huntingGround.spa';

const TableMap = {
    '1': {
        'Table': TableDice,
        'Description': 'Dados'
    },
    '2': {
        'Table': TableTraitCost,
        'Description': 'Costes de Rasgos'
    },
    '3': {
        'Table': TableMortalsTemplate,
        'Description': 'Plantillas de Mortales'
    },
    '4': {
        'Table': TableDifficulty,
        'Description': 'Dificultad de las Acciones'
    },
    '5': {
        'Table': TableAntagonists,
        'Description': 'Antagonistas Sencillos'
    },
    '6': {
        'Table': TableCriticalOptions,
        'Description': 'Opciones de Cr\u00EDticos/Fallos'
    },
    '7': {
        'Table': TableFeeding,
        'Description': 'Alimentarse'
    },
    '8': {
        'Table': TableGeneration,
        'Description': 'Generaci\u00F3n'
    },
    '9': {
        'Table': TableBloodPotency,
        'Description': 'Potencia de Sangre'
    },
    '10': {
        'Table': TableHumanity,
        'Description': 'Humanidad'
    },
    '11': {
        'Table': TableTouchstoneDamage,
        'Description': 'Da\u00F1ar y Destruir Piedras De Toque'
    },
    '12': {
        'Table': TableFrenzyProvocations,
        'Description': 'Provocaciones del Frenes\u00ED'
    },
    '13': {
        'Table': TableHumor,
        'Description': 'Humor'
    },
    '14': {
        'Table': TableResonance,
        'Description': 'Resonancia'
    },
    '15': {
        'Table': TableRandomTemperament,
        'Description': 'Temperamento Aleatorio y Resonancia'
    },
    '16': {
        'Table': TableRangedCombat,
        'Description': 'Combate a Distancia'
    },
    '17': {
        'Table': TableWeaponDamage,
        'Description': 'Da\u00F1o de Armas'
    },
    '18': {
        'Table': TableCripplingInjury,
        'Description': 'Heridas Incapacitantes'
    },
    '19': {
        'Table': TableArmor,
        'Description': 'Armadura'
    },
    '20': {
        'Table': TableSocialDamage,
        'Description': 'Da\u00F1o Social'
    },
    '21': {
        'Table': TableHuntingGround,
        'Description': 'Territorio de Caza'
    }
}

export { TableMap };