declare var require: any

var React = require('react');

const TableHuntingGround = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-21'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>TERRITORIO DE CAZA</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>TERRITORIO DE CAZA</th>
                        <th>DIFICULTAD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Barrio de chabolas, barrio marginal, proyecto de vivienda p&uacute;blica o banlieue, el Coto</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Barrio bohemio o hipster, barrio de clase obrera degradado o en proceso de gentrificaci&oacute;n</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Barrio de clase obrera saludable, centro de negocios de la ciudad, zonas tur&iacute;sticas, aeropuerto o casino</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>Zonas industriales, de almacenes o portuarias; zonas verdes urbanas; zonas residenciales de clase media</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>Barrio rico</td>
                        <td>6</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableHuntingGround;