declare var require: any

var React = require('react');

const TableResonance = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-14'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>RESONANCIA</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>RESONANCIA</th>
                        <th>DISCIPLINAS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Col&eacute;rica</td>
                        <td>Celeridad, Potencia.</td>
                    </tr>
                    <tr>
                        <td>Melanc&oacute;lica</td>
                        <td>Fortaleza, Ofuscaci&oacute;n.</td>
                    </tr>
                    <tr>
                        <td>Flem&aacute;tica</td>
                        <td>Auspex, Dominaci&oacute;n.</td>
                    </tr>
                    <tr>
                        <td>Sangu&iacute;nea</td>
                        <td>Hechicer&iacute;a de Sangre, Presencia.</td>
                    </tr>
                    <tr>
                        <td>Sangre animal</td>
                        <td>Animalismo, Protean.</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableResonance;