// @flow

import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import LeafElement from './LeafElement'
import { Element } from './Elements'
import Wrapper from './Wrapper'
import Icon from './Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeText } from './Text'

type Props = {
    data: any,
    toggle: Function,
    isOpen: boolean,
    isRoot: boolean,
    level: number,
    selected: any,
    onSelect: Function,
    currentTheme: string
}

const NodeElement = (props: Props) => {
    const { data, isOpen, isRoot, toggle, onSelect, selected, level, currentTheme } = props
    if (props.data === null) {
        return null
    }

    return (
        <Element
            isOpen={isOpen}
            isRoot={isRoot}
            currentTheme={currentTheme}
            selected={selected && selected.id === data.id}
        >
            <Wrapper level={level}>
                <Icon level={level} onClick={() => { toggle() }} currentTheme={currentTheme}>
                    <FontAwesomeIcon icon={data.isAddNew ? 'plus' : isOpen ? 'chevron-down' : 'chevron-right'} />
                </Icon>
                <NodeText onClick={() => { onSelect(data) }}>{data.label}</NodeText>
            </Wrapper>
        </Element>
    )
}

NodeElement.defaultProps = {
    data: null,
    toggle: () => { },
    isOpen: false,
    isRoot: false,
    level: 0,
    selected: null,
    onSelect: () => { },
    currentTheme: 'dark'
}

export default NodeElement
