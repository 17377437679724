declare var require: any

var React = require('react');

const TableHumanity = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-10'>
        <div className='item-content'>
            <div className='row'>
                <div className='col-8'>
                    <div className='red-label table-builder-red-header'>HUMANIDAD</div>
                    <table className='alternate-bg'>
                        <thead>
                            <tr>
                                <th>HUMANIDAD</th>
                                <th>DADOS SUMADOS A RESISTIR EL FRENES&Iacute;</th>
                                <th>DURACI&Oacute;N DEL LETARGO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Humanidad 9</td>
                                <td>3</td>
                                <td>Tres d&iacute;as.</td>
                            </tr>
                            <tr>
                                <td>Humanidad 8</td>
                                <td>2</td>
                                <td>Una semana.</td>
                            </tr>
                            <tr>
                                <td>Humanidad 7</td>
                                <td>2</td>
                                <td>Dos semanas.</td>
                            </tr>
                            <tr>
                                <td>Humanidad 6</td>
                                <td>2</td>
                                <td>Un mes.</td>
                            </tr>
                            <tr>
                                <td>Humanidad 5</td>
                                <td>1</td>
                                <td>Un a&ntilde;o.</td>
                            </tr>
                            <tr>
                                <td>Humanidad 4</td>
                                <td>1</td>
                                <td>Una d&eacute;cada.</td>
                            </tr>
                            <tr>
                                <td>Humanidad 3</td>
                                <td>1</td>
                                <td>Cinco d&eacute;cadas.</td>
                            </tr>
                            <tr>
                                <td>Humanidad 2</td>
                                <td>0</td>
                                <td>Un siglo.</td>
                            </tr>
                            <tr>
                                <td>Humanidad 1</td>
                                <td>0</td>
                                <td>Cinco siglos.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='col-4'>
                    <div className='red-label right-content'><strong>PERDER LA &Uacute;LTIMA GOTA</strong></div>
                    <div>Un vampiro que pierde su &uacute;ltimo punto de Humanidad, pasando de 1 a 0 (cero), cae en un Frenes&iacute; espectacular. Todos sus Atributos y puntuaciones se incrementan hasta 5 para esa escena; si sobrevive al Wassail se convierte en una criatura, prisionero de la voluntad de la Sangre, y en un PdN.</div>
                </div>
            </div>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableHumanity;