declare var require: any

var React = require('react');

import { userStore } from '../store/storeModule'
import { withAuthorization } from '../session/session';
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import { compose } from 'recompose';
import { MuuriComponent } from 'muuri-react';
import * as TABLE_SPA from './spa/tables.spa'

class TableBuilderBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterValue: '',
            sortValue: '',
            children: null,
            selectedTable: '1',
            comboOptions: []
        };

        this.gridRef = React.createRef();
        this.removeTable = this.removeTable.bind(this);
        this.layoutEnd = this.layoutEnd.bind(this);
    }

    unsubscribe: any;

    componentDidMount() {
        const authUser = this.props.authUser;
        this.props.firebase.screen(authUser.uid).get().then(screen => {
            const items = screen.exists ? screen.data().screenItems : [];
            let comboOptions = [];
            let promises = [];
            let promises2 = [];
            let children = [];
            Object.keys(TABLE_SPA.TableMap).forEach((key) => {
                if (!items.includes(key)) {
                    promises.push(comboOptions.push({
                        value: key,
                        description: TABLE_SPA.TableMap[key].Description
                    }));
                }
            });

            Promise.all(promises).then(() => {
                items.forEach(item => {
                    let NewTable = TABLE_SPA.TableMap[item].Table;
                    promises2.push(children.push((<NewTable key={item} id={item} onRemove={this.removeTable} />)));
                });

                Promise.all(promises2).then(() => {
                    let selectedTable = comboOptions.length > 0 ? comboOptions[0].value : '';
                    this.setState({
                        children: children,
                        comboOptions: comboOptions,
                        selectedTable: selectedTable
                    });
                });
            });
        });
    }

    componentWillUnmount() {
        //this.unsubscribe();
    }

    onChangeTable(value) {
        this.setState({ selectedTable: value });
    }

    removeTable(key) {
        let children = this.state.children.filter(c => c.props.id != key);
        let comboOptions = this.state.comboOptions ? [...this.state.comboOptions] : [];
        comboOptions.push({
            value: key,
            description: TABLE_SPA.TableMap[key].Description
        });

        let selectedTable = comboOptions[0].value;

        this.setState({
            children: children,
            comboOptions: comboOptions,
            selectedTable: selectedTable
        });
    }

    addTable() {
        const key = this.state.selectedTable;
        const authUser = this.props.authUser;
        if (key) {
            let children = this.state.children ? [...this.state.children] : [];
            let NewTable = TABLE_SPA.TableMap[key].Table;
            children.push((<NewTable key={key} id={key} onRemove={this.removeTable} />))
            let comboOptions = this.state.comboOptions.filter(o => o.value != key);
            let selectedTable = comboOptions.length > 0 ? comboOptions[0].value : '';

            this.setState({
                children: children,
                comboOptions: comboOptions,
                selectedTable: selectedTable
            });
        }
    }

    dragReleaseEnd(item) {
        var pos = item.getGrid().getItems().indexOf(item);
    }

    layoutEnd(items) {
        const authUser = this.props.authUser;
        const screenItems = [];
        let promises = [];
        items.map(item => {
            promises.push(screenItems.push(item.getProps().id));
        });

        Promise.all(promises).then(() => {
            this.props.firebase.screen(authUser.uid).set({
                screenItems
            });
        });
    }

    render() {
        const { filterValue, sortValue, children, selectedTable } = this.state;

        return (
            <div className='table-builder-container'>
                <div className='toolbox container'>
                    <div className='table'>
                        <div className='row'>
                            <div className='col'>
                                <select id='addTable' className='form-control' value={selectedTable} onChange={e => this.onChangeTable(e.target.value)}>
                                    <ComboOptions options={this.state.comboOptions} />
                                </select>
                            </div>
                            <div className='col'>
                                <button className='btn btn-danger' onClick={() => this.addTable()}>
                                    Agregar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <MuuriComponent ref={this.gridRef} filter={filterValue} sort={sortValue} dragEnabled={true} dragHandle='table-builder-drag' onMount={(grid) => {
                    grid.on('dragReleaseEnd', this.dragReleaseEnd);
                    grid.on('layoutEnd', this.layoutEnd);
                }}>
                    {children}
                </MuuriComponent>
            </div>
        );
    }
}

const ComboOptions = ({ options }) => (
    options.map(option => (
        <option key={'opt' + option.value} value={option.value}>{option.description}</option>
    ))
);



const condition = authUser => !!authUser;

const TableBuilder = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(userStore),
)(TableBuilderBase);

export default TableBuilder;