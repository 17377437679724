declare var require: any

var React = require('react');

import { createStore, applyMiddleware, compose } from 'redux'
import CharacterReducer from '../reducers/characterReducer'
import UserReducer from '../reducers/userReducer'
import FirebaseMiddleware from '../firebase/firebase'
import thunk from 'redux-thunk';

export const characterStore = compose(createStore)(CharacterReducer, applyMiddleware(thunk))
//export const characterStore = createStore(CharacterReducer)

export const mapStateToCharacterProps = state => {
    return {
        character: state.character,
        chronicles: state.chronicles
    };
};

export const userStore = compose(createStore)(UserReducer)

export const mapStateToUserProps = state => {
    return {
        user: state.user
    };
};