declare var require: any

var React = require('react');

import CharacterAttributeFree from './characterAttributeFree';

export default class CharacterMeritsFlaws extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            merits: [],
            onAddMerit: null,
            onSetAttribute: null,
            onChangeField: null
        };
    }

    handleClick() {
        let nextId = 'discipline' + this.state.merits.length;
        let newMerit = { name: nextId, description: '', rating: 0 };
        let merits = this.state.merits.concat(newMerit);
        this.setState({ merits });
    }

    renderDisciplines() {
        return this.props.merits?.map(merit => {
            return (
                <div key={'meritsRow' + merit.id} className='row'>
                    <CharacterAttributeFree key={'merits' + merit.id} id={merit.id} name={merit.name} rating={merit.value}
                        onSetAttribute={this.props.onSetAttribute} onChangeField={this.props.onChangeField} />
                </div>
            );
        });
    }

    render() {
        return (
            <div className='container disciplines' >
                <div className='row'>
                    <div className='col-6 label'>
                        <label>Ventajas y Defectos</label>
                    </div>
                    <div className='col-6 addButton'>
                        <button className='btn btn-danger' onClick={this.props.onAddMerit}>
                            Agregar
                    </button>
                    </div>
                </div>
                {this.renderDisciplines()}
            </div>
        );
    }
}