declare var require: any

var React = require('react');

import { Provider } from "react-redux";

export const withStore = store => Component => {
    class WithStore extends React.Component {
        render() {
            return (
                <Provider store={store}>
                    <Component {...this.props} />
                </Provider>
            );
        }
    }

    return WithStore;
};