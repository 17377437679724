declare var require: any

var React = require('react');

const TableTouchstoneDamage = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-11'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>DA&Ntilde;AR Y DESTRUIR PIEDRAS DE TOQUE</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>ACCI&Oacute;N</th>
                        <th>M&Aacute;CULAS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Vincular con Sangre a un mortal</td>
                        <td>+1</td>
                    </tr>
                    <tr>
                        <td>Abrazar a un mortal</td>
                        <td>+2</td>
                    </tr>
                    <tr>
                        <td>Piedra de Toque sufre da&ntilde;o</td>
                        <td>+1</td>
                    </tr>
                    <tr>
                        <td>Piedra de Toque sufre da&ntilde;o por tus acciones</td>
                        <td>+2</td>
                    </tr>
                    <tr>
                        <td>Piedra de Toque destruida</td>
                        <td>+2</td>
                    </tr>
                    <tr>
                        <td>Piedra de Toque destruida por tus acciones</td>
                        <td>+3</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableTouchstoneDamage;