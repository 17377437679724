declare var require: any

var React = require('react');

const TableArmor = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-19'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>ARMADURA</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>TIPO DE ARMADURA</th>
                        <th>VALOR DE ARMADURA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Ropa reforzada / cuero pesado</td>
                        <td>2 (0 contra balas)</td>
                    </tr>
                    <tr>
                        <td>Ropa antibalas</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Chaleco antibalas / chaqueta flak</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>Armadura t&aacute;ctica SWAT / armadura militar (un dado de penalizaci&oacute;n a las tiradas de Destreza)</td>
                        <td>6</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableArmor;