declare var require: any

var React = require('react');

import CharacterField from './characterField';
import CharacterFieldCombo from './characterFieldCombo';

export default class CharacterSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            character: null,
            onChangeField: null,
            chronicles: []
        };
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='1' label='Nombre' name='name' value={this.props.character?.name} onChangeField={this.props.onChangeField} />
                    </div>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='2' label='Concepto' name='concept' value={this.props.character?.concept} onChangeField={this.props.onChangeField} />
                    </div>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='3' label='Depredador' name='predator' value={this.props.character?.predator} onChangeField={this.props.onChangeField} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-lg-4'>
                        <CharacterFieldCombo id='4' label='Cr&oacute;nica' name='chronicle' value={this.props.character?.chronicle} onChangeField={this.props.onChangeField}
                            options={this.props.chronicles} />
                    </div>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='5' label='Ambici&oacute;n' name='ambition' value={this.props.character?.ambition} onChangeField={this.props.onChangeField} />
                    </div>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='6' label='Clan' name='clan' value={this.props.character?.clan} onChangeField={this.props.onChangeField} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='7' label='Sire' name='sire' value={this.props.character?.sire} onChangeField={this.props.onChangeField} />
                    </div>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='8' label='Deseo' name='desire' value={this.props.character?.desire} onChangeField={this.props.onChangeField} />
                    </div>
                    <div className='col-12 col-lg-4'>
                        <CharacterField id='9' label='Generaci&oacute;n' name='generation' value={this.props.character?.generation} onChangeField={this.props.onChangeField} />
                    </div>
                </div>
            </div>
        );
    }
}