export const LANDING = '/';
export const CHRONICLES = '/chronicles';
export const CHRONICLE = '/chronicle';
export const CHRONICLE_ID = '/chronicle/:id';
export const STORIES = '/stories';
export const CHARACTERS = '/characters';
export const CHARACTER_ID = '/character/:id';
export const CHARACTER = '/character';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const INVITE = '/invite';
export const MAPS = '/maps';
export const TABLES = '/tables';
export const MAP = '/map';
export const MAP_ID = '/map/:id';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_VERIFY = '/emailVerify';
export const FRIENDS = '/friends';