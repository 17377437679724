declare var require: any

var React = require('react');

import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from './constants/routes'
import * as ROLES from './constants/roles';
import SignOutButton from './admin/signout';
import { AuthUserContext } from './session/session';
import { withFirebase } from './firebase/context';
import { compose } from 'recompose';

class NavigationBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authUser: null,
        };
    }

    render() {
        return (
            <div>
                <AuthUserContext.Consumer>
                    {authUser =>
                        authUser ? authUser.emailVerified ? (
                            <NavigationAuth authUser={authUser} firebase={this.props.firebase} location={this.props.location} />
                        ) : (
                                <NavigationNotVerified authUser={authUser} />
                            ) : (
                                <NavigationNonAuth />
                            )
                    }
                </AuthUserContext.Consumer>
            </div>
        )
    };
}

class NavigationAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authUser: null,
            activeItem: 2,
            profilePicture: ''
        };
    }

    unsubscribe: any;

    navItemClass(i) {
        let location = '/' + this.props.location.pathname.split('/')[1];
        switch (location) {
            case ROUTES.CHRONICLE:
            case ROUTES.CHRONICLES:
                return i == 0 ? 'nav-item active' : 'nav-item'
            case ROUTES.STORIES:
                return i == 1 ? 'nav-item active' : 'nav-item'
            case ROUTES.CHARACTER:
            case ROUTES.CHARACTERS:
                return i == 2 ? 'nav-item active' : 'nav-item'
            case ROUTES.MAP:
            case ROUTES.MAPS:
                return i == 3 ? 'nav-item active' : 'nav-item'
            case ROUTES.TABLES:
                return i == 4 ? 'nav-item active' : 'nav-item'
            case ROUTES.FRIENDS:
                return i == 5 ? 'nav-item active' : 'nav-item'
            default:
                return 'nav-item'
        }
    }

    navItemSelected(i) {
        this.setState({
            activeItem: i
        });
    }

    componentDidMount() {
        const authUser = this.props.authUser;
        this.props.firebase.downloadProfilePicture(authUser.uid).then((downloadURL) => {
            this.setState({ profilePicture: downloadURL });
        }).catch(() => {
            this.props.firebase.downloadProfilePicture('empty_profile.jpg').then((downloadURL) => {
                this.setState({ profilePicture: downloadURL });
            });
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe)
            this.unsubscribe();
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <a className="navbar-brand" href="#">Vampiro</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className={this.navItemClass(0)} onClick={() => this.navItemSelected(0)} data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className='nav-link' to={ROUTES.CHRONICLES}>Cr&oacute;nicas</Link>
                        </li>
                        <li className={this.navItemClass(1)} onClick={() => this.navItemSelected(1)} data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className='nav-link' to={ROUTES.STORIES}>Historias</Link>
                        </li>
                        <li className={this.navItemClass(2)} onClick={() => this.navItemSelected(2)} data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className='nav-link' to={ROUTES.CHARACTERS}>Personajes</Link>
                        </li>
                        <li className={this.navItemClass(3)} onClick={() => this.navItemSelected(3)} data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className='nav-link' to={ROUTES.MAPS}>Mapas</Link>
                        </li>
                        <li className={this.navItemClass(4)} onClick={() => this.navItemSelected(4)} data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className='nav-link' to={ROUTES.TABLES}>Tablas</Link>
                        </li>
                        <li className={this.navItemClass(5)} onClick={() => this.navItemSelected(5)} data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className='nav-link' to={ROUTES.FRIENDS}>Amigos</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto nav-flex-icons">
                        <li className="nav-item dropdown user-dropdown">
                            <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-333" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <img className='profile-picture' src={this.state.profilePicture} />
                                {this.props.authUser.username}
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-default bg-dark"
                                aria-labelledby="navbarDropdownMenuLink-333">
                                <Link className='nav-link' to={ROUTES.ACCOUNT}>Cuenta</Link>
                                {!!this.props.authUser.roles[ROLES.ADMIN] && (
                                    <Link className='nav-link' to={ROUTES.ADMIN}>Admin</Link>
                                )}
                                {!!this.props.authUser.roles[ROLES.ADMIN] && (
                                    <Link className='nav-link' to={ROUTES.INVITE}>Invitar</Link>
                                )}
                                <SignOutButton />
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    };
}

class NavigationNotVerified extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authUser: null,
            activeItem: 0
        };
    }

    navItemClass(i) {
        if (this.state.activeItem == i) {
            return 'nav-item active'
        } else {
            return 'nav-item'
        }
    }

    navItemSelected(i) {
        this.setState({
            activeItem: i
        });
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <a className="navbar-brand" href="#">Vampiro</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className={this.navItemClass(0)} onClick={() => this.navItemSelected(0)} data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className='nav-link' to={ROUTES.PASSWORD_VERIFY}>Verificar Correo</Link>
                        </li>
                        <li className={this.navItemClass(1)} data-toggle="collapse" data-target=".navbar-collapse.show">
                            <SignOutButton />
                        </li>
                    </ul>
                </div>
            </nav>
        )
    };
}

class NavigationNonAuth extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <a className="navbar-brand" href="#">Vampiro</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className='nav-item' data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className='nav-link' to={ROUTES.SIGN_IN}>Iniciar Sesi&oacute;n</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    };
}

const condition = authUser => !!authUser;

const Navigation = compose(
    withFirebase,
    withRouter
)(NavigationBase);

export default Navigation;