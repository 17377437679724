declare var require: any

var React = require('react');

import WoDCheckBox from './woDCheckBox';

export default class CharacterConsumable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkBoxCount: 10,
            consumable: null,
            onSetConsumable: null
        };
    }

    isChecked(i) {
        if (this.props.consumable) {
            if (this.props.consumable.maxValue < i)
                return '.'
            else if (this.props.consumable.aggravatedValue >= i)
                return 'X'
            else if (this.props.consumable.lethalValue >= i)
                return '/'
            else return null;
        } else {
            return null;
        }
    }

    createTable = () => {
        const checkCount = this.props.checkBoxCount ? this.props.checkBoxCount : 10;
        let children = []
        // Outer loop to create parent
        for (let i = 1; i <= checkCount; i++) {
            children.push(<WoDCheckBox key={i + this.props.consumable.id} id={i} value={this.isChecked(i)} onClicked={this.props.onSetConsumable} />);
            if (i % 5 == 0) {
                children.push(<div key={i+'S'} className="cbSeparator" />);
            }
        } 

        return children;
    }

    render() {
        return (
            <div className='centered-content consumable'>
                {this.createTable()}
            </div>
        );
    }
}