declare var require: any

var React = require('react');

import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';
import { withFirebase } from '../firebase/context';
import * as ROUTES from '../constants/routes';

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        listener: any;
        userListener: any;

        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(
                authUser => {
                    if (!condition(authUser)) {
                        this.props.history.push(ROUTES.SIGN_IN);
                    }

                    this.userListener = this.props.firebase.onUserListener(
                        authUser => {
                            if (!condition(authUser)) {
                                this.props.history.push(ROUTES.SIGN_IN);
                            }
                        },
                        () => this.props.history.push(ROUTES.SIGN_IN),
                    );
                },
                () => this.props.history.push(ROUTES.SIGN_IN),
            );
        }

        componentWillUnmount() {
            if (this.listener)
                this.listener();

            if (this.userListener)
                this.userListener();
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser =>
                        condition(authUser) ? <Component authUser={authUser} {...this.props} /> : null
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return compose(
        withRouter,
        withFirebase,
    )(WithAuthorization);
};

export default withAuthorization;