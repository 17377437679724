declare var require: any

var app = require('firebase/app');

//import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
    auth: any;
    db: any;
    storage: any;

    constructor() {
        if (!app.apps.length) {
            app.initializeApp(config);
        }

        //app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage();
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) => {
        return new Promise((res, rej) => {
            this.db.collection('validEmails').doc(email).get().then((doc) => {
                if (doc.exists) {
                    res(this.auth.createUserWithEmailAndPassword(email, password));
                } else {
                    rej({ message: 'No autorizado' });
                }
            });
        });
    }

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => {
        this.auth.signOut();
    }

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) => {
        let thisRef = this;
        return this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                if (authUser.emailVerified) {
                    thisRef.user(authUser.uid)
                        .get()
                        .then(snapshot => {
                            const dbUser = snapshot.data();

                            // default empty roles
                            if (!dbUser.roles) {
                                dbUser.roles = {};
                            }

                            // merge auth and db user
                            authUser = {
                                uid: authUser.uid,
                                email: authUser.email,
                                emailVerified: authUser.emailVerified,
                                ...dbUser,
                            };

                            next(authUser);
                        });
                } else {
                    authUser = {
                        uid: authUser.uid,
                        email: authUser.email,
                        emailVerified: authUser.emailVerified,
                    };

                    next(authUser);
                }
            } else {
                fallback();
            }
        });
    }

    onUserListener = (next, fallback) => {
        if (this.auth.currentUser) {
            return this.user(this.auth.currentUser.uid).onSnapshot(snapshot => {
                let authUser = this.auth.currentUser;
                if (authUser) {
                    if (authUser.emailVerified) {
                        const dbUser = snapshot.data();

                        // default empty roles
                        if (!dbUser.roles) {
                            dbUser.roles = {};
                        }

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            ...dbUser,
                        };

                        next(authUser);
                    } else {
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                        };

                        next(authUser);
                    }
                } else {
                    fallback();
                }
            }, error =>{
                console.log('Error on onUserListener');
            });
        } else {
            return new Promise((next, fallback) => {
                next();
            });
        }
    }

    // *** User API ***

    doAddUser = (user) => {
        this.db.collection('users').doc(user.id).set(user);
        this.db.collection('userRef').doc(user.id).set({ email: user.email });
    }

    validEmail = (email) => this.db.collection('validEmails').doc(email);

    users = () => this.db.collection('users');

    user = (id) => this.db.collection('users').doc(id);

    userByMail = (email) => this.db.collection('userRef').where('email', '==', email)

    userRef = (id) => this.db.collection('userRef').doc(id);

    characters = () => this.db.collection('characters');

    character = (id) => this.db.collection('characters').doc(id);

    chronicles = () => this.db.collection('chronicles');

    chronicle = (id) => this.db.collection('chronicles').doc(id);

    stories = () => this.db.collection('stories');

    story = (id) => this.db.collection('stories').doc(id);

    invitations = (id) => this.db.collection('invitations').doc(id);

    childrenStoriesByRoot = (id) => this.db.collection('stories').where('root', '==', id);

    childrenStoriesByParent = (owner, id) => this.db.collection('stories').where('owner', '==', owner).where('parent', '==', id);

    relMaps = () => this.db.collection('relMaps');

    relMap = (id) => this.db.collection('relMaps').doc(id);

    screen = (id) => this.db.collection('screens').doc(id);

    // *** Storage ***

    uploadPicture = (id, file) => {
        var storageRef = this.storage.ref();

        return storageRef.child('characters/' + id).put(file);
    }

    deletePicture = (id) => {
        var storageRef = this.storage.ref();        
        return storageRef.child('characters/' + id).delete();
    }

    downloadPicture = (id) => {
        var storageRef = this.storage.ref();

        return storageRef.child('characters/' + id).getDownloadURL();
    }

    uploadProfilePicture = (id, file) => {
        var storageRef = this.storage.ref();

        return storageRef.child('profiles/' + id).put(file);
    }

    deleteProfilePicture = (id) => {
        var storageRef = this.storage.ref();

        return storageRef.child('profiles/' + id).delete();
    }

    downloadProfilePicture = (id) => {
        var storageRef = this.storage.ref();

        return storageRef.child('profiles/' + id).getDownloadURL();
    }
}

export default Firebase;