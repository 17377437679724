declare var require: any

var React = require('react');

import { withFirebase } from '../firebase/context';

const SignOutButton = ({ firebase }) => (
    <button className='nav-link borderless-button' type="button" onClick={firebase.doSignOut}>
        Cerrar Sesi&oacute;n
    </button>
);

export default withFirebase(SignOutButton);