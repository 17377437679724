declare var require: any

var React = require('react');

import CharacterAttribute from './characterAttribute';

export default class CharacterAttributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            character: null,
            onSetAttribute: null
        };
    }

    findById(id) {
        if (this.props.character.attributes) {
            return this.props.character.attributes.find((element) => {
                return element.id === id;
            })
        } else {
            return undefined;
        }
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 sectionHeader'>
                        ATRIBUTOS
                        <hr />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                        <div className='centered'>F&iacute;sicos</div>
                        <CharacterAttribute id='1' label='Fuerza' name='Strength' rating={this.findById(1)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='2' label='Destreza' name='Dexterity' rating={this.findById(2)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='3' label='Resistencia' name='Stamina' rating={this.findById(3)?.value} onSetAttribute={this.props.onSetAttribute} />
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                        <div className='centered'>Sociales</div>
                        <CharacterAttribute id='4' label='Carisma' name='Charisma' rating={this.findById(4)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='5' label='Manipulaci&oacute;n' name='Manipulation' rating={this.findById(5)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='6' label='Compostura' name='Composure' rating={this.findById(6)?.value} onSetAttribute={this.props.onSetAttribute} />
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                        <div className='centered'>Mentales</div>
                        <CharacterAttribute id='7' label='Inteligencia' name='Intelligence' rating={this.findById(7)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='8' label='Astucia' name='Wits' rating={this.findById(8)?.value} onSetAttribute={this.props.onSetAttribute} />
                        <CharacterAttribute id='9' label='Resoluci&oacute;n' name='Resolution' rating={this.findById(9)?.value} onSetAttribute={this.props.onSetAttribute} />
                    </div>
                </div>
            </div>
        );
    }
}