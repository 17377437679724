declare var require: any

var React = require('react');

import { withFirebase } from '../firebase/context';
import { withAuthorization } from '../session/session';
import { compose } from 'recompose';

class EmailVerificationBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null
        }
    }

    sendVerification() {
        this.props.firebase.auth.currentUser.sendEmailVerification().then(() => {
            this.setState({ message: 'Se envi\u00F3 correo de verificaci\u00F3n' });
        }).catch((error) => {
            this.setState({ message: error });
        });
    }

    render() {
        const { message } = this.state;

        return (
            <div className='container email-verify'>
                <div className='row'>
                    <div className='col-12'>
                        <button className='btn btn-danger' onClick={() => this.sendVerification()}>
                            Verificar Correo
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        {message && <p>{message}</p>}
                    </div>
                </div>
            </div>
        );
    }
}

const condition = authUser => !!authUser;

const EmailVerification = compose(
    withAuthorization(condition),
    withFirebase
)(EmailVerificationBase);

export default EmailVerification;