declare var require: any

var React = require('react');

const TableGeneration = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-8'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>GENERACI&Oacute;N</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>GENERACI&Oacute;N</th>
                        <th>POTENCIA DE SANGRE M&Iacute;NIMA</th>
                        <th>POTENCIA DE SANGRE M&Aacute;XIMA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>4&#170;</td>
                        <td>5</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>5&#170;</td>
                        <td>4</td>
                        <td>9</td>
                    </tr>
                    <tr>
                        <td>6&#170;</td>
                        <td>3</td>
                        <td>8</td>
                    </tr>
                    <tr>
                        <td>7&#170;</td>
                        <td>3</td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td>8&#170;</td>
                        <td>2</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>9&#170;</td>
                        <td>2</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>10&#170;-11&#170;</td>
                        <td>1</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>12&#170;-13&#170;</td>
                        <td>1</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>14&#170;-16&#170;</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableGeneration;