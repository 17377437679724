declare var require: any

var React = require('react');

import AuthUserContext from './context';
import { withFirebase } from '../firebase/context';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        listener: any;
        userListener: any;

        constructor(props) {
            super(props);
            this.state = {
                authUser: null,
            };
        }

        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(
                authUser => {
                    this.setState({ authUser });

                    this.userListener = this.props.firebase.onUserListener(
                        authUser => {
                            this.setState({ authUser });
                        },
                        () => {
                            this.setState({ authUser: null });
                        },
                    );
                },
                () => {
                    this.setState({ authUser: null });
                },
            );
        }

        componentWillUnmount() {
            if (this.listener)
                this.listener();

            if (this.userListener)
                this.userListener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return withFirebase(WithAuthentication);
};

export default withAuthentication;