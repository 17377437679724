declare var require: any

var React = require('react');

import * as REQUEST_TYPES from '../constants/requestTypes'
import * as MESSAGES from '../constants/messages.js'
import * as ROLES from '../constants/roles.js'
import { userStore } from '../store/storeModule'
import { withAuthorization } from '../session/session';
import { withFirebase } from '../firebase/context';
import { withStore } from '../store/withStore'
import { compose } from 'recompose';

class ChronicleBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            players: [],
            addingPlayer: false,
            playerMail: ''
        };

        this.delPlayer = this.delPlayer.bind(this);
    }

    unsubscribe: any;
    unsubscribePending: any;

    showAddPlayer() {
        this.setState({ addingPlayer: true });
    }

    cancelAddPlayer() {
        this.setState({ addingPlayer: false });
    }

    handleNewName(name) {
        this.setState({ playerMail: name });
    }

    addPlayer() {
        const chronicleId = this.props.match?.params.id;
        const authUser = this.props.authUser;
        this.props.firebase.chronicle(chronicleId).get().then((chronicle) => {
            this.props.firebase.userByMail(this.state.playerMail).get().then((players) => {
                players.forEach(player => {
                    this.props.firebase.invitations(player.id).set({
                        [chronicleId]: {
                            name: chronicle.data().name,
                            owner: authUser.uid,
                            request: REQUEST_TYPES.CHRONICLE
                        }
                    }, { merge: true });

                    let friends = authUser.friends ? { ...authUser.friends } : {};
                    if (!friends[player.id]) {
                        friends[player.id] = this.state.playerMail;

                        this.props.firebase.user(authUser.uid).set({
                            friends: friends
                        }, { merge: true });
                    }

                    this.setState({ playerMail: '', addingPlayer: false });

                    let playerList = chronicle.data().players ? { ...chronicle.data().players } : {};
                    if (!playerList[player.id]) {
                        playerList[player.id] = ROLES.PLAYER;

                        this.props.firebase.chronicle(chronicleId).set({
                            players: playerList
                        }, { merge: true });
                    }
                });
            });
        });
    }

    delPlayer(id, name) {
        const chronicleId = this.props.match?.params.id;
        if (confirm("\u00BFEliminar a " + name + "?")) {
            this.props.firebase.chronicle(chronicleId).get().then((chron) => {
                let players = chron.data().players.filter((player) => player != id);

                this.props.firebase.chronicle(chronicleId).set({
                    players: players
                }, { merge: true });
            });
        }
    }

    componentDidMount() {
        const chronicleId = this.props.match?.params.id;
        this.unsubscribe = this.props.firebase.chronicle(chronicleId).onSnapshot(currentChronicle => {
            this.setState({ players: [], loading: true });
            if (currentChronicle.data().players && currentChronicle.data().players.length > 0) {
                currentChronicle.data().players.forEach((player, index) => {
                    this.props.firebase.user(player).get().then((doc) => {
                        let playerList = !this.state.players ? [] : [...this.state.players];
                        if (doc.exists) {
                            playerList.push({
                                id: doc.id,
                                name: doc.data().username,
                                email: doc.data().email
                            });
                        }

                        this.setState({
                            players: playerList,
                            loading: index < Object.keys(currentChronicle.data().players).length - 1,
                        });
                    }).catch(error => {
                        this.props.firebase.userRef(player).get().then((doc) => {
                            let playerList = !this.state.players ? [] : [...this.state.players];

                            playerList.push({
                                id: doc.id,
                                name: MESSAGES.PENDING,
                                email: doc.data().email
                            });

                            this.setState({
                                players: playerList,
                                loading: index < Object.keys(currentChronicle.data().players).length - 1,
                            });
                        });
                    });
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }


    render() {
        const { players, loading, addingPlayer, invitations, loadingInvitations } = this.state;

        return (
            <div>
                <div className='container chronicle'>
                    <h2>Jugadores</h2>
                    {loading && <div>Loading ...</div>}

                    <PlayersList players={players} delFunction={this.delPlayer} />
                    <div className='row addButton'>
                        <button className='btn btn-danger' onClick={() => this.showAddPlayer()}>
                            Agregar
                        </button>
                    </div>
                    {addingPlayer &&
                        <div>
                            <br />
                            E-Mail: <input type='text' name='chronicleName' id='chronicleName' value={this.state.playerMail} onChange={(e) => this.handleNewName(e.target.value)} />
                            <div className='row addButton'>
                                <button className='btn btn-danger' onClick={() => this.addPlayer()}>
                                    Ok
                                </button>
                                <button className='btn btn-danger' onClick={() => this.cancelAddPlayer()}>
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const PlayersList = ({ players, delFunction }) => (
    <ul className='character-list'>
        {players.map(player => (
            <li key={player.id}>
                <div className='row'>
                    <div className='col-11'>
                        {player.name} ({player.email})
                    </div>
                    <div className='col-1'>
                        <button className='btn btn-danger' onClick={() => delFunction(player.id, player.name)}>
                            <i className='fas fa-trash'></i>
                        </button>
                    </div>
                </div>
            </li>
        ))}
    </ul>
);

const condition = authUser => !!authUser;

const Chronicle = compose(
    withAuthorization(condition),
    withFirebase,
    withStore(userStore),
)(ChronicleBase);

export default Chronicle;