declare var require: any

var React = require('react');

const TableTraitCost = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-2'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>COSTES DE RASGOS: EXPERIENCIA</div>
            <hr className='table-separator-2' />
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>RASGO</th>
                        <th>PUNTOS DE EXPERIENCIA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Incrementar Atributo</td>
                        <td>Nuevo nivel x5</td>
                    </tr>
                    <tr>
                        <td>Incrementar Habilidad</td>
                        <td>Nuevo nivel x3</td>
                    </tr>
                    <tr>
                        <td>Nueva Especialidad</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>Disciplina de Clan</td>
                        <td>Nuevo nivel x5</td>
                    </tr>
                    <tr>
                        <td>Otras Disciplinas</td>
                        <td>Nuevo nivel x7</td>
                    </tr>
                    <tr>
                        <td>Disciplina Caitiff</td>
                        <td>Nuevo nivel x6</td>
                    </tr>
                    <tr>
                        <td>Ritual de Hechicer&iacute;a de Sangre</td>
                        <td>Nivel del Ritual x3</td>
                    </tr>
                    <tr>
                        <td>F&oacute;rmula de Sangre D&eacute;bil</td>
                        <td>Nivel de la F&oacute;rmula x3</td>
                    </tr>
                    <tr>
                        <td>Ventaja</td>
                        <td>3 por punto</td>
                    </tr>
                    <tr>
                        <td>Potencia de Sangre</td>
                        <td>Nuevo nivel x10</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableTraitCost;