declare var require: any

var React = require('react');

const TableSocialDamage = ({ id, onRemove }) => (
    <div key={id} className='item table-builder table-builder-size-20'>
        <div className='item-content'>
            <div className='red-label table-builder-red-header'>DA&Ntilde;O SOCIAL</div>
            <table className='alternate-bg'>
                <thead>
                    <tr>
                        <th>P&Uacute;BLICO O TESTIGOS</th>
                        <th>DA&Ntilde;O ADICIONAL A LA FUERZA DE VOLUNTAD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>S&oacute;lo oponentes</td>
                        <td>+0</td>
                    </tr>
                    <tr>
                        <td>Tu coterie</td>
                        <td>+1</td>
                    </tr>
                    <tr>
                        <td>V&aacute;stagos cuya opini&oacute;n valoras por s&iacute; misma: tu mentor, tu amante, etc.</td>
                        <td>+2</td>
                    </tr>
                    <tr>
                        <td>Primog&eacute;nitos, Arp&iacute;as u otras figuras socialmente importantes; otro rival serio aparte de tu oponente actual</td>
                        <td>+3</td>
                    </tr>
                    <tr>
                        <td>El Pr&iacute;ncipe, el Bar&oacute;n u otra figura poderosa</td>
                        <td>+4</td>
                    </tr>
                </tbody>
            </table>
            <div className='card-remove' onMouseDown={() => onRemove(id)}>
                <i className='fas fa-times'></i>
            </div>
        </div>
    </div>
);

export default TableSocialDamage;