declare var require: any

var React = require('react');

export default class CharacterAttributeFree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkBoxCount: 5,
            id: null,
            name: null,
            rating: 1,
            onSetAttribute: null,
            onChangeField: null
        };
    }

    isChecked(i) {
        return this.props.rating >= i;
    }

    onChange() {
    }

    createTable = () => {
        const checkCount = this.props.checkBoxCount ? this.props.checkBoxCount : 5;
        let children = []
        // Outer loop to create parent
        for (let i = 1; i <= checkCount; i++) {
            children.push(
                <input key={i + this.props.id} type='radio' id={'rd' + i + this.props.id} onClick={() => this.props.onSetAttribute(this.props.id, i)}
                    checked={this.isChecked(i)} onChange={this.onChange} />
            );

            if (i % 5 == 0) {
                children.push(<div key={i + 'S'} className="cbSeparator" />);
            }
        }

        return children;
    }

    render() {
        return (
            <div className='charAttributeFree'>
                <div><input type='text' id={'txt' + this.props.id} name={'txt' + this.props.id} value={this.props.name} onChange={e => this.props.onChangeField(this.props.id, e.target.value)} /></div>
                <div>
                    {this.createTable()}
                </div>
            </div>
        );
    }
}